<template>
    <div class="warning-top-wrapper d-flex flex-column aling-items-end">
      <Warning
        v-for="(item, index) in this.$store.getters.get_warnings_list" :key="index"
        class="warning-margin"
        :warningType="item.warningType"
        :warningText="item.warningText"
        @close="this.removeWarning(index)"
      />
    </div>
</template>

<script>
import Warning from '@/components/service/Warning';

export default {
    components : {
        Warning
    },
    methods: {
        removeWarning(index) {
            this.$store.commit("REMOVE_WARNING", index);
        }
    }
}
</script>

<style lang="scss" scoped>
    .warning-top-wrapper {
        position: fixed;
        z-index: 4000;
        padding-top: 117px;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
  
    .warning-margin {
        margin-bottom: 20px;
    }
</style>