export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polski"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzacja"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapomniałeś hasła?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwszy raz tutaj?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskiwanie hasła"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon lub e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskaj"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odzyskiwanie hasła"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugie imię:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data urodzenia:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaloguj się"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik znaleziony"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacja"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry/Komentarz"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak operacji"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma operacji"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychody:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" szt. na kwotę"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typy operacji"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłaty, opłaty za usługi"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy wewnętrzne"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłata rachunku"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy na własne rachunki"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un bon de crypto-monnaie"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation des bons de crypto-monnaie"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód/Wydatek"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatek"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= równe"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ nie równe"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> większe"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ większe lub równe"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< mniejsze"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ mniejsze lub równe"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończone sukcesem"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W trakcie"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziś"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia operacji"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie konta"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie wybrane"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana na giełdzie"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie adresu"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy wewnątrz portfela"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działanie"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paszport cyfrowy"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Więcej"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Użytkownika:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data rejestracji:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia autoryzacja:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz wszystkie"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładuj"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłać"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kont"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło potwierdzenia"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby potwierdzić transakcje, należy utworzyć hasło potwierdzające."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popularne płatności"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomości"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwiń"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywów"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzyć"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balansować"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycofać"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełniać"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do użytkownika"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogólny balans"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzać"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilans niefiatowy"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilans Fiata"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje rejestracyjne"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzono"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicznie dostępne"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajdź"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W grupie"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupy płatności"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota płatności"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto przelewu"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablon"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelej"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowywane konto"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sposób płatności"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie konta"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły przelewu"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź przelew"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o przelewach"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrukcja"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie adresu"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowywany adres"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do listy zadań"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres przelewu"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz operację"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia operacji"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kont"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany błąd"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista tokenów"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto przelewu:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrona transakcji"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ochrony:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dni"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz jako szablon"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelej"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew do innego użytkownika"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod ochrony — to kod, który musi wprowadzić odbiorca, aby zakończyć transakcję. Chroniony przelew odbiorca zobaczy od razu w sekcji \"Historia operacji\", ale będzie mógł skorzystać z pieniędzy dopiero po wprowadzeniu kodu ochrony w karcie transakcji. Jeśli ważność płatności wygasła, środki wracają do nadawcy."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres przelewu"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skąd"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokąd"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelej"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy na własne konta"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelewy wewnątrz portfela"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coś poszło nie tak"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpłata"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowy adres"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konto"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń wybrane"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kont"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystko"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa konta"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć konta?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć konto"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono aktywów"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono kont"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana nazwy adresu"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć adresy?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć adres"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekret"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełniać"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wycofać"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrywać"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ adresu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ konta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz adres"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie nowego adresu"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie nowego konta"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczeństwo"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja antyphishingu jest dodatkowym mechanizmem ochrony podczas pracy z kontem."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzone przez Ciebie powitanie będzie wyświetlane w górnej części każdej strony konta."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prawidłowa fraza pokaże, że jesteś na właściwej stronie, nieprawidłowa fraza będzie sygnałem: nie wykonuj operacji, natychmiast skontaktuj się z działem bezpieczeństwa naszego systemu."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz antyphishing:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraza antyphishingu:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przeciwpoślizg"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany wejdą w życie po następnej autoryzacji"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja dostępu na podstawie adresu IP uniemożliwi Tobie (lub napastnikowi) pracę z systemem z adresu, który nie jest wymieniony na liście dozwolonych."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenie dostępu do konta na podstawie adresów IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj adresy"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blok adresów: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twój IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista adresów"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od adresu"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do adresu"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak adresów"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabroń"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź adres IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprawidłowy adres IP"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie określono żadnego adresu IP ani bloku adresów."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żaden adres IP nie jest dozwolony do autoryzacji. Nie będziesz mieć dostępu do swojego konta."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostęp na podstawie IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magiczne słowo to dodatkowe ustawienie bezpieczeństwa."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli magiczne słowo jest włączone, przy każdej autoryzacji system poprosi o wprowadzenie pewnych znaków (według ich numeru) z magicznego słowa."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytaj magiczne słowo:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy wejściu do konta:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy płatnościach przez merchanta:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przy użyciu karty:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magic Word"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze znacznie zwiększają bezpieczeństwo systemu."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas logowania program poprosi nie tylko o login i hasło, ale także o kolejny jednorazowy klucz. Aktywacja odbywa się sekwencyjnie. Ostatni klucz jest ważny do momentu utworzenia nowej serii kluczy."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj jednorazowych kluczy"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kluczy"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednorazowe klucze"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare hasło:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nowe hasło\" i \"Potwierdzenie hasła\" nie pasują do siebie."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana hasła"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 4 do 63 znaków. Dozwolone są litery alfabetu łacińskiego, cyfry i symbole"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stare hasło:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe hasło:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie hasła:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Nowe hasło\" i \"Potwierdzenie hasła\" nie pasują do siebie."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło nie powinno zawierać cyrylicy"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie hasła potwierdzającego"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana hasła potwierdzenia"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do strony głównej"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od 4 do 65 znaków. Dozwolone są litery alfabetu łacińskiego, cyfry i symbole"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W przypadku zapomnienia hasła do konta, będziesz mógł je odzyskać, odpowiadając na pytanie zabezpieczające."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W tej sekcji możesz zmienić pytanie/odpowiedź zabezpieczające."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie zabezpieczające"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje pytanie:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pytanie zabezpieczające"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Własne pytanie"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para kapitałowa"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj wymiany"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew po kursie rynkowym - wymiana zostanie dokonana po najlepszym kursie w momencie realizacji zlecenia. Uwaga, kwota do otrzymania może się zmienić i różnić od obliczonej w zależności od ofert w momencie wymiany."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew po stałym kursie odbywa się zgodnie z określoną przez Ciebie kwotą i kursem. Zostanie wystawione zlecenie. Jeśli określony kurs znacznie różni się od rynkowego, realizacja zlecenia może potrwać dłużej."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs (prognoza):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs nieokreślony, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zaleca się określenie kursu samodzielnie"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj wszystkiego"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamawiać"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamawiać"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łącznie:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po rynku"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(szybka wymiana po najlepszej ofercie)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stały kurs "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(wystaw zlecenie z określonym kursem)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak odpowiednich kont"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana w ciągu 24 godzin"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wahania w ciągu 24 godzin"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolumen transakcji w ciągu 24 godzin"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota nie może być równa zeru!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymień"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby zobaczyć dane podsumowania, wybierz parę handlową"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można wymieniać identycznych aktywów"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowo"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja nie jest uwzględniana w selekcji do momentu"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giełda"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rynek. Podsumowanie"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podobne oferty"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty przeciwdziałające"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dają"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymują"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje zlecenia"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnie transakcje"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dałem"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data i czas"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prowizja"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ofert"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak transakcji"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zleceń"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie zrealizowane"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie rozpoczęto przetwarzania"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęto przetwarzanie"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie anulowane"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane i anulowane"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zlecenia"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie zlecenia"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prowizja"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zleceń"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna kwota oddawana"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna kwota oddawana"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna kwota otrzymywana"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna kwota otrzymywana"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba prób dokonania transakcji"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddawana waluta"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymywana waluta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto kredytowe"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista subdealerów"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie wybrane"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziś"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ daty nie wybrany"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończenie"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakończ"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jaka część jest stała, a jaka zmienna"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stała kwota oddawana"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stała kwota otrzymywana"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ zlecenia"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik chce szybko wymienić po najlepszych ofertach przeciwdziałających"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana po stałym kursie"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie rozpoczęto przetwarzania"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpoczęto przetwarzanie"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie zrealizowane"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Całkowicie anulowane"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Częściowo zrealizowane i anulowane"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekazywane aktywo"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymywane aktywo"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ daty"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaj sumę"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymaj sumę"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcji"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ofert"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty P2P"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID sprzedawcy"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod oferty"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca zweryfikowany"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tylko dla zweryfikowanych użytkowników"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można dokonać transakcji z samym sobą"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrać aktywo"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misja"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kryptoaktywów"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywów"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja tokena"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptacja"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rynek"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokładność"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pierwotny"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wtórny"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównany z innym aktywem"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegółowe informacje na temat aktywów kryptograficznych"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj dane o zasobie kryptograficznym"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy problem"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zasób kryptograficzny"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęcie"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działalność"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIE"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny można kupić w kryptomacie"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokeny można sprzedać w kryptomacie"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyświetlanie tokenów"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dla konkretnego użytkownika"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID użytkownika"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalanie aktywów"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa jest pełną nazwą kryptowaluty, tak jak będzie to widoczne dla każdego: samego emitenta, kupujących, sprzedających, przetwarzającego itp."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrót zawierający od 3 do 10 znaków. \nDopuszczalne są wielkie litery łacińskie, łączniki (myślniki) i cyfry."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz kategorię, do której najlepiej pasuje Twój zasób kryptograficzny"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwane formaty: png, jpg, jpeg, svg. \nRozmiar pliku nie większy niż 1 MB, 500*500 pikseli"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opisz, dlaczego ten krypto-aktywo jest emitowane przez emitenta, w jakiej dziedzinie działa lub będzie działać, jaka jest jego misja."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel / Misja / Przeznaczenie:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość jednostki:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba miejsc po przecinku dla kwoty. Na przykład dokładność większości walut fiducjarnych, takich jak dolar amerykański lub euro, wynosi 2"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba jednostek emitowanego aktywa kryptograficznego. Kwota emisji będzie natychmiast dostępna na koncie użytkownika po zakończeniu transakcji tworzenia zasobu kryptograficznego."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadeklarowana wartość jednostki:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabezpieczenie:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba zobowiązana:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decyzja o emisji kryptoaktywów:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksportuj operacje do blockchain:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ blockchain:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz transakcje kryptoaktywów:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia/Sankcje:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Określ, którzy obywatele lub rezydenci podatkowi których krajów z powyższej listy nie mogą używać kryptoaktywów w swoich obrotach: "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne ograniczenia i warunki:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres hostingu:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres white paper:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne kontakty:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do poprzedniego"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz aktywa"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa i przeznaczenie"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakterystyka finansowa"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport do blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity i ograniczenia"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieokreślona"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stałe powiązanie z walutami fiducjarnymi i innymi aktywami"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent (osoba wydająca aktywa kryptograficzne)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferujący (osoba dystrybuująca aktywa kryptograficzne)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadawca"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiorca"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja (włącz sponsoring)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieszczenie aktywów kryptograficznych"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa kryptograficzne oznaczają cyfrową reprezentację wartości lub prawa, które mogą być przenoszone i przechowywane elektronicznie przy użyciu technologii blockchain. Aktywa kryptograficzne NIE obejmują walut fiducjarnych, papierów wartościowych i innych instrumentów finansowych oraz aktywów podlegających odrębnym regulacjom rządowym."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zasób kryptograficzny"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administracja"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęcie"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód odmowy przyjęcia"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto oferenta"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rachunek oferenta w obowiązującej walucie"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferujący jest przedstawicielem emitenta aktywa w transakcjach kupna/sprzedaży na rynku pierwotnym. \nKonto jest pasywne. \nMoże być kontem emisji aktywów"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto jest pasywne, powiązane walutowo. \nMusi należeć do tego samego użytkownika co poprzednie konto"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj użytkownikom na zakup aktywów w bankomatach"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na sprzedaż aktywów przez użytkowników w bankomatach"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz zakupu na rynku wtórnym"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na rynku wtórnym przeprowadzane są za pośrednictwem bramek"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz sprzedaży na rynku wtórnym"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na rynku wtórnym przeprowadzane są za pośrednictwem bramek"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na transakcje z aktywami na giełdzie"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwalaj na transakcje dotyczące aktywów za pośrednictwem protokołu P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli jesteś indywidualnym przedsiębiorcą lub przedstawicielem osoby prawnej, musisz pobrać plik decyzji o wydaniu zasobu cyfrowego w formacie PDF. \nRozwiązanie musi zawierać wszystkie główne cechy wirtualnego zasobu i być podpisane elektronicznym podpisem indywidualnego przedsiębiorcy lub tej osoby prawnej. \nPrzykład decyzji o emisji zasobu cyfrowego można znaleźć na stronach naszego serwisu w sekcji „Dokumenty”"])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umieszczenie wirtualnego zasobu"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastępcze konto wydania"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz z kont wystawców"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota do odpisu:"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
        "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. \nemisja:"])},
        "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wielkość emisji przekracza maks. \nemisje"])},
        "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maks. \nemisja jest mniejsza niż wielkość emisji lub równa zeru"])},
        "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formy są nieobecne"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa wirtualne"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustawienia powiadomień"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom o operacjach"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzacja (logowanie do konta, aplikacji mobilnej itp.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom przy zdarzeniu \"Dokonywanie operacji finansowych za pomocą karty\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wydatki (wszelkie płatności, przelewy, opłaty za usługi itp.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wpływy pieniężne"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymanie listu przez wewnętrzną pocztę"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymanie rachunku do zapłaty"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana ustawień bezpieczeństwa"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź operacje kodem z wiadomości"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź autoryzację kodem z wiadomości"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź przy \"Dokonywaniu operacji finansowych za pomocą karty\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź zmianę ustawień bezpieczeństwa kodem z wiadomości"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź wydatki kodem z wiadomości"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiany nie zapisane, zapisać?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie zapisuj"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data wpływu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data przetwarzania"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba płatności"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w tym udanych"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["w tym błędnych"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostawca usług"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. parametr"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono płatności"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz dokonać kilku płatności jednocześnie. \nW tym celu należy najpierw utworzyć plik z opisem płatności w formacie „XLS”, „XLSX”, „CSV”."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza pliku"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o pliku"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączna liczba płatności: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na łączną kwotę: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik płatności"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykonaj płatności"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie pliku płatności"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź zawartość pliku"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe i przelewy"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usługa płatności masowych umożliwia szybkie przesyłanie środków do innych użytkowników oraz dokonywanie operacji płatności na rzecz dostawców usług."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W sekcji"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj plik płatności"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["możesz załadować plik w formacie Excel (XLS, XLSX, CSV) lub XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista zadań"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pozwala zobaczyć statusy wcześniej utworzonych wniosków o masowe wypłaty. "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz utworzyć plik w edytorze tabel Excel. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz przykładowy plik"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ważne uwagi:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format pliku - CSV, XLS lub XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plik musi być bez nagłówków."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby długie liczby nie zmieniały się w format wykładniczy (np. \"5,46546E+15\") i nie były obcinane wiodące zera, przed liczbą można umieścić symbol pojedynczego cudzysłowu ('), lub przed wprowadzeniem danych ustawić dla komórki format tekstowy."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla płatności na rzecz dostawców usług"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolumn w pliku:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Określa się z listy operatorów podanych"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutaj"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Dla wszystkich operatorów telefonii komórkowej można określić jako kod operatora 7000 - w takim przypadku operator zostanie określony automatycznie na podstawie numeru telefonu.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest określona w aktywie, z którego dokonywany jest przelew. Jeśli kontrahent nie posiada takiego aktywa, do transakcji automatycznie stosuje się kurs przeliczeniowy systemu."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do płatności"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wpisuje się wartość parametru wymaganego przez operatora, na przykład numer telefonu. Wielu operatorów ogranicza się do jednego parametru płatności, internetowi, telewizji komercyjnej itp, ale jeśli liczba parametrów jest większa niż jeden, musisz podać je wszystkie w sąsiednich kolumnach od pierwszej."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry płatności można zobaczyć, przechodząc do opłaty jakiegokolwiek operatora:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla masowych przelewów użytkownikom"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Określa się 0 lub nie określa się wcale."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest wyświetlana w aktywie, z którego aktywo jest przelewane. Jeśli kontrahent nie posiada takiego aktywa, do transakcji automatycznie stosuje się kurs przeliczeniowy systemu."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do płatności"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto odbiorcy"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Tutaj wpisuje się numer konta użytkownika, na które należy dokonać przelewu (20 cyfr)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format kolumn dla masowego udzielania pożyczek przez agenta kredytowego"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Ta linia wskazuje, że płatności stanowią pożyczki."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Kwota jest podawana w walucie produktu kredytowego."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do pożyczki"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie nie jest obowiązkowe, można zostawić puste; długość nie więcej niż 255 znaków."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID pożyczkobiorcy"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Zwróć uwagę, że pożyczkobiorca musi mieć cyfrowy paszport Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod produktu kredytowego"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Można go znaleźć na liście produktów kredytowych przy ręcznym udzielaniu pożyczki."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Po prostu liczba okresów (jakie okresy są używane, określa produkt kredytowy, np. dni, tygodnie, miesiące itp.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod operatora (numer formularza)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wypełnianie tego i następnych pól nie jest obowiązkowe, jeśli ich nie wypełnisz, pożyczkobiorca będzie mógł później samodzielnie dokonać wypłaty pożyczki przez swoje konto."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj w przypadku błędu"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Anuluj (1) udzieloną pożyczkę, jeśli transakcja wypłaty zakończy się błędem, lub zostaw do późniejszej samodzielnej wypłaty (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Wpisuje się wartość parametru wymaganego przez operatora, na przykład numer telefonu. Wielu operatorów ogranicza się do jednego parametru płatności, internetowi, telewizji komercyjnej itp.), ale jeśli liczba parametrów jest większa niż jeden, musisz podać je wszystkie w sąsiednich kolumnach od pierwszej."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametr płatności N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ostatnia wiadomość"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak zgłoszeń"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorytet"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temat"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treść wiadomości"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz przesłać jeden lub więcej plików. Maksymalny rozmiar pliku to 10 MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niski"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalny"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysoki"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wsparcie techniczne"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne zgłoszenia"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiwum"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe zgłoszenie"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przetwarzane przez operatora"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie utworzone"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwają prace, oczekuj odpowiedzi operatora"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zamknięte"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zamknięte"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak nowych wiadomości"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowa wiadomość od operatora"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak nowych wiadomości"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij do archiwum"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie prośby"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doładowanie"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przelew"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wypłata"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pola"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak szablonów"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiorca"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć szablon nr"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę szablonu "])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień nazwę"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użyj"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o kontrahencie"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Konto / E-mail / Telefon"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Adres kryptograficzny / E-mail / Telefon"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje ogólne"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o płatności"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis kontrolny"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje sklepy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono sklepów"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz kod widżetu"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz link do płatności"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń sklep"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy na pewno chcesz usunąć sklep"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb płatności"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptacja"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestruj sklep"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarejestrowany w systemie sklep elektroniczny umożliwia przyjmowanie płatności od klientów przez merchant system."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jako sklep rejestrują się sklepy elektroniczne, punkty wymiany i wszelkie inne zasoby internetowe chcące przyjmować płatności na swoje konto."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa lub marka"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL sklepu"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krótki opis sklepu"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ serwisu"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep aktywny"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klucz tajny"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zezwól na automatyczne wypłaty"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysyłaj wyniki na skrypt"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres skryptu"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powrót po udanej płatności"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po NIEudanej płatności"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co najmniej 30 znaków."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Używany do sprawdzania integralności i wiarygodności przesyłanych danych przez system płatności (przy wysyłaniu użytkownika na merchant) i sklep (przy otrzymywaniu wyników płatności)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do listy sklepów"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista faktur"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono faktur"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklep"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzny aktyw"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonimy"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli wypełnione, ogłoszenie będzie dostępne tylko dla użytkowników z wymienionych krajów"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiąż go z aktywem"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrócone"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek przelewu"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie jest aktywne"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko przez link"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko dla zweryfikowanych użytkowników"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do poprzedniego"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opublikuj"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ograniczenia"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie ogłoszenia"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Własny zewnętrzny aktyw"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wiązać"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmniejsz kurs"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak konta w takim aktywie"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj kurs"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs prawidłowy"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmniejsz kurs"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przywróć"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum większe niż maksimum"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdzie i jak chcesz otrzymać przelew. Na przykład: Przelej w aplikacji bankowej na numer telefonu +499990001111 (odbiorca Aleksander N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaję"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane dane"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakie dane musi przesłać taker, abyś mógł dokonać przelewu"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zasób"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz zasób"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co chcesz otrzymać: środki w aktywie wewnętrznym, na przykład BTC, czy w aktywie zewnętrznym, na przykład TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz komentarz o szczegółach przeprowadzenia tej umowa, na przykład: \\\"Aktywa zewnętrzne są przekazywane tylko podczas osobistego spotkania\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rodzaj wartości, do których należy zasób zewnętrzny, na przykład „gotówka”"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa aktywa do wyświetlenia na platformie"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywo może być również nazywane słowami z listy"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porównanie z istniejącym aktywem"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład: litry, sztuki, godziny, pudełka"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na przykład: l, szt, godz, pud"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile cyfr może być po przecinku w wartościach aktywa"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrałeś stworzenie jeszcze nieistniejącego aktywa, proszę wypełnij jego charakterystyki"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas dany stronom na wykonanie warunków"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywem wewnętrznym jest aktywo istniejące w systemie \\\"New Reality\\\". Aktywem zewnętrznym jest aktywo nieistniejące w systemie \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieprzypisany"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartość oczekiwana: od 1 do 2147483"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powód"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj transakcję"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź udział w transakcji"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć transakcję"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przedłuż czas oczekiwania"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulowanie transakcji"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status użytkownika w transakcji nieokreślony. Coś poszło nie tak"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś o arbitraż"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgódź się na arbitraż"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiadom o wykonaniu przelewu"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź otrzymanie przelewu"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oceń transakcję"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij ocenę"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja nr "])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dzisiaj"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jutro"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj arbitraż"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj arbitraż"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróciłeś się o arbitraż"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożono przeciwko Tobie wniosek o arbitraż"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwa postępowanie arbitrażowe (Jesteś powodem)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trwa arbitraż (jesteś pozwanym)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po upływie tego czasu zgoda na arbitraż nastąpi automatycznie i nie będzie już możliwości dodawania odpowiedzi na pytania arbitrażowe"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dajesz"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcji"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak transakcji"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferty"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korzyść"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod transakcji"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daję"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywnych transakcji"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje transakcje"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa wewnętrzne"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywne"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis aktywa"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak ogłoszeń"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje ogłoszenia"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje ogłoszenia"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nowe ogłoszenie"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywne"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywne"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wszystkie"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kierunek"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywo"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne aktywo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oddaję"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymuję"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limity"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data publikacji"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo na koncie"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapytanie transakcji"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedawca"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania działań stron"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dajesz"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymujesz"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poproś o transakcję"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz konto"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto nie wybrane"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota nie mieści się w przedziale:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekazywany"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymywany"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktywo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["daję"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wewnętrzny"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otrzymuję"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne aktywa"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywa nie znalezione"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywa"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zewnętrzny"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybrane:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wewnętrzne"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wczoraj"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zażądać arbitrażu"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulować"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysłać"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zewnętrzne aktywa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązany z aktywem"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres od"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres do"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizuj"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak aktywów"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzisiaj"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wczoraj"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tydzień"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesiąc"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiązany z aktywem"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń zewnętrzny zasób kryptograficzny"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalna"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit kwoty"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas oczekiwania (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogłoszenie aktywne"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko przez link"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostępne tylko dla zweryfikowanych użytkowników"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj zewnętrzny aktyw"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synonimy"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraje"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powiąż z aktywem"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostki miary"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skrót"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzja"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetuj"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaznacz wszystko"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomaty"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby rozszerzyć tabelę na pełny ekran"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zresetuj"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podsprzedawcy"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywny"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz bankomat"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kryptomat"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne informacje"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Główne"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny otwarcia"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis miejsca instalacji"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znormalizowany"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznormalizowany"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tryb pracy"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poniedziałek"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wtorek"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Środa"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czwartek"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piątek"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedziela"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przerwa obiadowa"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skorzystaj z istniejących kont"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz nowe konta"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godziny pracy (informacja orientacyjna)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywność"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono maszyn kryptograficznych"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer bankomatu"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szerokość"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["długość geograficzna"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infolinia"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator sub-dealera"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzać"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto tranzytowe"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stworzenie kryptomatu"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja kryptomatu"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tak"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakazać"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umożliwić"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolekcjonerów"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolektor"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można przejść do sald odbioru - brak danych"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kolekcjonerów"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolektor"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolony"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przyjęcie"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj bankomat"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz aktualne salda kolekcji"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobacz niezaładowane salda kolekcji"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod PIN"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nie określisz subdealera, bankomat zostanie z Tobą połączony"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerokość i długość geograficzna to współrzędne geograficzne, które można skopiować np. z Google Maps wpisując adres bankomatu"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejsce instalacji"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby wyświetlić tabelę, należy wybrać co najmniej jedną kolumnę"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratować"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzyć"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przejdź do bankomatów"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrole elektroniczne"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz czek"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działalność"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzony"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do odpisu"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seria"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["działania"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywnie"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieaktywny"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żadnych kontroli"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzać"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknąć"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono e-rachunków"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie paragonu elektronicznego"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto do odpisu"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominał każdego czeku"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kontroli"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z powrotem"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzyć"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seria:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod aktywacyjny:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utworzono czeki"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierz plik z paragonami"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywacja czeku elektronicznego"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rachunek depozytowy"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seria"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod aktywacyjny"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdzać"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktywuj"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, wystąpił błąd!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja bankomatów"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kolekcję"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtry"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastawić"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualizacja"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pokazuj zbiorów archiwalnych"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie pokazuj anulowanych kolekcji"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak kolekcji"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Złożono wniosek o zbiórkę"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj kolekcję"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień status kolekcji"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodawanie kolekcji"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawowe informacje"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podstawy"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szafarka"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba kart na aplikację"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptant rachunku"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcjonerzy gotówki"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uwaga dla kolekcjonerów"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kolekcji"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ładowanie nie jest w porządku"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymień kasetę akceptora banknotów"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kart"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij wszystkie karty z kasety dozownika kart odrzucanych"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij wszystkie karty z tuby"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer punktu jest polem wymaganym, które należy wypełnić"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba banknotów w kasecie 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość w kasecie 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba banknotów w kasecie 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do taśmy 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratować"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edycja kolekcji"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer kasety"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identyfikator kasety"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer kolekcji kasety"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocena kasety"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załadowany"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serwer"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktycznie"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz do kasety"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak danych do wyświetlenia"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja urządzeń"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ważny"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przez"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serwer"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bankomat"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba rachunków"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szafarka"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć/Wycofaj"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptant rachunku"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odrzuć karty"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karty"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktualny"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rozładowany"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salda kolekcji bankomatów"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja urządzeń"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ważny"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaseta zainstalowana w bankomacie"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schwytany"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szczątki są wynoszone"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusta kaseta:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przyczepność:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe informacje:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do tej pory"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz stan"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek o zbiórkę został złożony"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie zbiórki anulowane"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja zatwierdzona do realizacji"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiór dokonywany jest przez kasjera (pieniądze ładowane są do kaset)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasety przekazane kolekcjonerom (pieniądze w drodze)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbiórka gotówki zainstalowana w bankomacie"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W wyniku awarii przeciążony inną kolekcją"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolekcja została zastąpiona inną kolekcją w bankomacie (pozostaje w drodze)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salda kolekcji są księgowane (kolekcja wysyłana jest do archiwum)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj informacje o kolekcjonerze"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulować"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratować"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór konta oferenta"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pola formularza nr"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brak wymaganego identyfikatora"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomyślnie się zalogowałeś, kontynuuj operacje w bankomacie"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj transakcje bankomatowe"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sporne kolumny"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastawić"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waluta"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz raport"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próbka"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobierać"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arkusz"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urządzenia bankomatowe"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj urządzenie"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ urządzenia"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj kasetę"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ kasety"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracja"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń urządzenie"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyjmij kasetę"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć urządzenie?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usunąć kasetę?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pary walutowe"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz parę walutową"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj parę walutową"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń parę walutową"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wejście"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizacji"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie znaleziono par walutowych"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz parę walutową"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para walut"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurowanie aktualizacji"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podana waluta"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano walutę"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj zysk do kursu,%"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ aktualizacji kursu"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas życia kursu, sekundy"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interwał aktualizacji kursu, sekundy"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrze"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do jakiego terminu ważny jest kurs?"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​danej waluty"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​otrzymanej waluty"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wejście"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konieczne jest wybranie waluty i to w dodatku tylko jednej: danej lub otrzymanej"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota wysłania * Stawka = Kwota otrzymania"])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utwórz prośbę o pomoc"])}
    },
    "ShowInfoVA": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane o zasobach kryptograficznych"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres wydania:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serce:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprawnienia"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel/Misja/Cel"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakterystyka finansowa"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategoria"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokładność:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łączność"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisja:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba odpowiedzialna:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koszt jednostkowy:"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpieczeństwo:"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zakupy w bankomatach:"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprzedaż w bankomatach:"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje aktywami na giełdzie:"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje na aktywach za pośrednictwem P2P:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres hostingu"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres Białej Księgi"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferent"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne kontakty"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iść"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitent"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksymalna emisja:"])}
    },
    "Doc": {
      "Merchant": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linia o długości do 255 znaków"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20 cyfr"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba ułamkowa, precyzja dwie cyfry, separator kropki"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciąg do 255 znaków, opcjonalnie"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita, opcjonalna"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciąg 32 znaków"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["przechowuj numer transakcji; \nmusi być unikalny dla każdej płatności; \npodczas przekazywania ciągu"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unikalny numer zostanie wygenerowany automatycznie"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer sklepu nadawany po rejestracji"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer konta sklepu; \nkonto to określa także w jakiej walucie należy dokonać płatności (w większości przypadków użytkownik może dokonać płatności w dowolnej walucie, a środki zostaną automatycznie przeliczone na walutę odbiorcy)"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zabronić klientowi zmiany określonej kwoty (1 - zakaz, 0 - zezwolenie)"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kwota beneficjenta w walucie rachunku beneficjenta"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer formularza za pośrednictwem którego akceptowana jest płatność"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komentarz wyświetlany płatnikowi jako cel płatności"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dodatkowy opcjonalny parametr, który będzie przekazywany do skryptu wyniku płatności, a także do stron zwrotnych"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, pod który klient powróci po udanej płatności; \njeśli nie jest określony, jest pobierany z ustawień sklepu"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, na który klient powróci po wystąpieniu błędu; \njeśli nie jest określony, jest pobierany z ustawień sklepu"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres URL, pod który zostanie wysłane powiadomienie o wynikach płatności; \njeśli nie jest określony, jest pobierany z ustawień sklepu"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["okres ważności faktury (w minutach), domyślnie 60"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podpisanie wiadomości tajnym kluczem sklepu"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 - jeśli się powiedzie, 0 - w przeciwnym razie"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liczba całkowita"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kod błędu (0 jeśli nie ma błędu)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linia"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tekst błędu (lub wyjaśnienie statusu płatności)"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0 lub 1"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sygnał, że ostateczny status płatności nie jest jeszcze znany"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numer transakcji sklepu"])}
      }
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba fizyczna"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osoba prawna"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwisko:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imię:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugie imię:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie litery, a także myślnik i apostrof."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kowalski"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie litery, a także myślnik, spacja i apostrof."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kowalski"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są polskie i łacińskie litery, a także myślnik, cudzysłów, spacje, cyfry i apostrof."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sp. z o.o. \"Rogi i kopyta\""])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dozwolone są litery łacińskie (duże i małe), cyfry, symbole _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz hasło"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasła nie pasują"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wstecz"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestracja"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalej"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane użytkownika"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj poprawny adres e-mail lub numer telefonu. Numer telefonu podaje się w formacie międzynarodowym, na przykład: +48123456789."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostałeś zarejestrowany"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz możesz "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zalogować się do konta"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twoje konto zostanie sprawdzone przez naszego pracownika, o wynikach aktywacji poinformujemy Cię później"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wysłano wiadomość z linkiem do potwierdzenia rejestracji"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na Twój adres e-mail"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po pierwszej autoryzacji zostaniesz poproszony o potwierdzenie numeru telefonu"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odbiorca: "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik nie znaleziony"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakty"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szczegóły"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybór użytkowników"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukces"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdzenie"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transakcja "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry ogólne"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana salda na kontach"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na koniec operacji:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na początku operacji:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowe parametry"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacje w blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Następna próba:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odpowiedź"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrona transakcji"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ochrony:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod ochrony:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametry szablonu"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa szablonu :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj operację"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz operację"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz szablon"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer transakcji"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operacja"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korespondent"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer formularza płatności"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przychód/Wydatek"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmiana salda adresów"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź zaliczenie"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróć pieniądze nadawcy"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podejrzana operacja"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcje działań:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa: "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użytkownik nie znaleziony"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zamknij"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasło:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź hasło płatności"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powtórz operację"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historia operacji"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista kont"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukces"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany błąd"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje aktywa"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacje o nowym nabytku"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy problem"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do blockchaina"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadzenie:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz adres"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejscowość"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okreg"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejon"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miasto"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teren miejski"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miejscowość"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy element adresu"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precyzowanie"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod pocztowy"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgłoszenie do wsparcia technicznego"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["od"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj wiadomość"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz przesłać jeden lub więcej plików. Maksymalny rozmiar pliku to 10 MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treść wiadomości"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazwa"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tworzenie widżetu dla"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generuj"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatność w"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota płatności"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania w przypadku sukcesu"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Działania w przypadku niepowodzenia"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generacja kodu / linku do płatności"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr operacji sklepu"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy parametr"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL sukcesu"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL błędu"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL powiadomienia"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który klient wróci po udanej płatności"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który klient wróci po błędzie"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, na który zostanie wysłane powiadomienie o wynikach płatności"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ustal kwotę"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiuj"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link do płatności"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentacja"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres ważności rachunku do płatności w minutach, domyślnie 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czas ważności linku"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularz"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widżet"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli nie ustalisz kwoty, płatnik będzie mógł ją wprowadzić samodzielnie (przydaje się np. przy wpłatach na cele charytatywne)"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrywać"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj sklep"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli to widzisz, formularz nie został odebrany (ale nie powinieneś tego widzieć)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowa emisja"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptoaktywa"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto - źródło pokrycia"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto debetowe prowizji"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatkowy problem"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentarz"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontynuuj"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualny rozmiar wydania"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZNAK"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powłoka"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obliczanie zasięgu"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiążący:"])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anulować"])}
        },
        "BurnEmission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalanie aktywów"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot faktury"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilość spalania"])}
        },
        "PaySelect": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody uzupełnienia"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptowaluta"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnienie przez P2P"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola elektroniczna"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony uzupełniania"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody wyjściowe"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W kryptowalucie"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katalog płatności"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatności masowe"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wniosek przez P2P"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony wniosków"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metody tłumaczenia"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana na wymianę"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tłumaczenie na użytkownika"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś według swoich kont"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szablony tłumaczeń"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnij wynik za pomocą Bitcoin, Tether USDT, Ethereum, Tron lub innych kryptowalut."])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana bez pośredników: Tłumaczasz kryptowalutę, transfer bankowy lub przesyłanie gotówki na rzecz innego użytkownika i jest to przelew wewnętrzny do Ciebie."])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czy nabyłeś gdzieś elektroniczną kontrolę usługi? \nAktywuj go wkrótce i uzyskaj całą wartość nominalną na swój własny koszt. \nNawiasem mówiąc, możesz sprawdzić kontrolę bez aktywacji."])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzupełnij wynik za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuń fundusze na Bitcoin, Tether USDT, Ethereum, Tron lub inną kryptowalutę."])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapłać komunikację komórkową, mieszkanie wspólne lub inne usługi."])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrób dziesiątki, setki, a nawet tysiące operacji jednym kliknięciem. \nWystarczy pobrać plik z listą formatu Excel."])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymiana bez pośredników: Tłumaczasz kryptowalutę, transfer bankowy lub przesyłanie gotówki na rzecz innego użytkownika i jest to przelew wewnętrzny do Ciebie."])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przypisz środki za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możesz ustawić swój kurs i poczekać, aż kurs stanie się właśnie tym. \nLub szybko wymieniać się w obecnej stawce rynkowej."])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przenieś środki do innego użytkownika usług."])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łatwo rozpowszechniać fundusze między kontami."])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szybko przenoszą środki za pomocą wcześniej utworzonych szablonów. \nNawiasem mówiąc, szablony można tworzyć po transakcji lub z historii operacji."])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do odjęcia"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do zaliczenia"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie można obliczyć kwoty"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz plik"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten format pliku nie jest obsługiwany. \nAkceptowane formaty:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieznany format"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za duży rozmiar pliku, maksymalny rozmiar: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz lub przeciągnij pliki tutaj do załadowania"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wybierz"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załaduj"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pliki załadowane"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plików"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuwać"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wynik:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odśwież status"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przydatne linki"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inne ustawienia bezpieczeństwa"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wymagane potwierdzenie"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzację należy potwierdzić jednorazowym kluczem"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoryzację należy potwierdzić magicznym słowem"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejestrację należy potwierdzić kodem z wiadomości SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["znaków"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedozwolona wartość"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pole wymagane"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź pola"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknij, aby wybrać, które kolumny mają być wyświetlane"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Język"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzień dobry"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzień dobry"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobry wieczór"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobranoc"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyloguj się"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokazano:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["łącznie"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na stronie"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokaż"])}
      },
      "Header": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potwierdź płatność"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oblicz prowizję za zwrot"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwróć płatność"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash w blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryzyko AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kwota"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowizja"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do zaliczenia"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli zgadzasz się z zmienionymi warunkami, potwierdź zaliczenie:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeśli te warunki Ci nie odpowiadają, możesz zwrócić środki na konto nadawcy z odliczeniem kosztów zwrotu:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcja"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na kwotę"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ma ryzyko AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dlatego za jej zaliczenie przeliczona jest prowizja"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tak więc kwota do zaliczenia wynosi"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie wykryto nowych wpływów"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwrot"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z powrotem"])}
      }
    }
  }
}