<template>
  <header class="header container-fluid">
    <div
      class="d-flex flex-row align-items-center justify-content-between h-100"
      :class="{ 'container-xl': !this.isMobile }"
    >
      <router-link :to="'/'" class="logo">
        <span class="d-flex align-items-center">
          <span class="logo-margin">
            <img
              v-if="this.$store.state.logoMobile.img != 'mobile_logo_default.svg'"
              :style="this.$store.state.logoMobile.style"
              :src="this.$store.state.logoMobile.img"
            />
            <img
              v-else
              :style="this.$store.state.logoMobile.style"
              :src="require(`@/assets/${this.$store.state.logoMobile.img}`)"
            />
          </span>
          <span v-if="this.$store.state.resize.w >= 480">
            <img
              v-if="this.$store.state.logoTablet.img != 'tablet_logo_default.svg'"
              :style="this.$store.state.logoTablet.style"
              :src="this.$store.state.logoTablet.img"
              :class="{ logo_mobile: this.isMobile }"
            />
            <img
              v-else
              :style="this.$store.state.logoTablet.style"
              :src="require(`@/assets/${this.$store.state.logoTablet.img}`)"
              :class="{ logo_mobile: this.isMobile }"
            />
          </span>
        </span>
      </router-link>

      <div ref="menu" class="col menu height d-flex flex-row align-items-center">
        <MenuItem
          v-for="(item, key) in this.mainMenuItems"
          :item="item"
          v-show="this.$store.state.resize.w >= 975"
          :key="key"
        />
      </div>

      <div class="burger col d-flex flex-row justify-content-end align-items-center gap-3 h-100">
        <div class="h-100 d-flex align-items-center justify-content-end position-relative">
          <div class="search-container d-flex flex-row align-items-center justify-content-center">
            <input
              ref="search_ref"
              v-model="searchQuery"
              class="search"
              :placeholder="$t('components.service.Header.1')"
              autocomplete="new-password"
              @input="this.filteredMenu"
            />
            <Icon name="search" :active="this.searchActive ? true : false" />
          </div>
          <Transition @before-enter="animateOpen" @leave="animateClose">
            <div class="search-results" v-show="this.searchResults.length && this.searchActive">
              <div v-for="(item, key) in this.searchResults" :key="key">
                <router-link class="d-block w-100 z-100" :to="item.to">
                  <p class="m-0">{{ item.main_text }}</p>
                  <p class="m-0 fs_08 grey" v-html="item.sub_text"></p>
                  {{ item.text }}
                </router-link>
              </div>
            </div>
          </Transition>
        </div>

        <MenuItem :item="this.userItems" type="user" :mobile="this.isMobile" /> 
        <MenuItem :item="this.burgerItems" type="burger" :mobile="this.isMobile" />

        <MenuItem v-if="this.isAdmin" :item="this.adminItems" type="admin-menu" :mobile="this.isMobile" />
      </div>
    </div>
  </header>
  <div class="container-fluid p-0 absh">
    <!-- <Keyts v-if="this.$store.state.userData.keyts" :key="this.$store.state.globalKeyKeyt" /> -->
    <div v-if="this.$store.state.userData.antiphishing" ref="antiph" class="antiphishing">
      <div class="container-xl p-0">
        <div class="container-fluid p-0 position-relative">
          <h5 class="antiphishing-text m-0 p-1 d-flex flex-row align-items-center justify-content-center">
            {{ this.$store.state.userData.antiphishing }}
          </h5>
          <i
            class="item-i cross"
            v-on:click="
              () => {
                this.$refs.antiph.remove();
              }
            "
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "@/components/service/topMenu/MenuItem.vue";
import Icon from "@/components/icons/Icon.vue";
import Keyts from "@/components/service/topMenu/Keyts.vue";
import { gsap } from "gsap";

export default {
  components: {
    MenuItem,
    Icon,
    Keyts,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      langDefault: process.env.VUE_APP_I18N_LOCALE,
      rawMenu: [],
      mainMenuItems: [],
      burgerItems: [],
      userItems: [],
      adminItems: [],
      searchResults: [],
      menuItemWidth: 140,
      maxItems: 0,
      searchQuery: "",
      searchActive: false,
      isMobile: false,
      scrolled: false,
      isAdmin: false,
    };
  },
  mounted() {
    this.isAdmin = this.$store.state.userData.is_admin;

    this.setupEventListeners();
    this.initializeMenu();
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  methods: {
    filteredMenu() {
      this.searchResults = [];
      const query = this.searchQuery.toLowerCase();
      if (!query) return;
      this.rawMenu.forEach((Item) => {
        if (Item.admin && !this.isAdmin) {
          return;
        }
        if (Array.isArray(Item.groups)) {
          this.filterGroups(Item, query);
        } else {
          console.warn("Item is not an array, check yaml", Item);
          return;
        }
      });
    },

    filterGroups(Item, query) {
      Item.groups.forEach((group) => {
        const checkRes = (localV) => {
          return localV.toLowerCase().includes(query);
        };
        const addUniqueResult = (result) => {

          const exists = this.searchResults.some((item) => item.to === result.to);

          if (!exists) {
            this.searchResults.push(result);
          }
        };
        let title = Item.title[this.locale] || Item.title[this.langDefault];
        let subtitle = group.subtitle[this.locale] || group.subtitle[this.langDefault];
        if (group.to) {
          if (checkRes(subtitle)) {
            addUniqueResult({ to: group.to, main_text: subtitle, sub_text: title });
          }
        } else if (group.list) {
          group.list.forEach((e) => {
            let content = e.content[this.locale] || e.content[this.langDefault];
            console.log('item', Item)
            console.log('group', group)
            console.log('e', e)
            if (checkRes(content)) {
              let text = `${title} &rarr; ${subtitle}`;
              addUniqueResult({ to: e.to, main_text: content, sub_text: text });
            }
          });
        }
      });
    },

    setupEventListeners() {
      window.addEventListener("resize", this.handleResize);
      this.$refs.search_ref.addEventListener("focus", () => (this.searchActive = true));
      this.$refs.search_ref.addEventListener("blur", () => (this.searchActive = false));
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.handleResize);
    },
    initializeMenu() {
      this.rawMenu = this.$store.state.menu;
      this.handleResize();
    },
    handleResize() {
      this.isMobile = this.$store.state.resize.w < 975;
      this.updateMenuItems();
    },
    updateMenuItems() {
      if (!this.$refs.menu) return;

      if (this.isMobile) {
        this.userItems = this.rawMenu.filter((item) => item.user);
        this.burgerItems = this.rawMenu.filter((item) => !item.user && !item.admin);
        this.adminItems = this.rawMenu.filter((item) => item.admin);
        this.mainMenuItems = [];
      } else {
        this.maxItems = Math.floor(this.$refs.menu.clientWidth / this.menuItemWidth);
        const mainItems = this.rawMenu.filter((item) => item.main);
        this.userItems = this.rawMenu.filter((item) => item.user);
        this.mainMenuItems = mainItems.slice(0, this.maxItems);
        this.burgerItems = [
          ...mainItems.slice(this.maxItems),
          ...this.rawMenu.filter((item) => !item.main && !item.user && !item.admin),
        ];
        this.adminItems = this.rawMenu.filter((item) => item.admin);
      }
    },
    animateOpen(target) {
      gsap.fromTo(target, { height: 0 }, { height: "auto", duration: 0.15, ease: "power1.inOut" });
    },
    animateClose(target, done) {
      gsap.to(target, { height: 0, duration: 0.15, ease: "power1.inOut", onComplete: done }).delay(0.1);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-family: "Nunito", sans-serif;
  height: 54px;
  position: sticky;
  z-index: 9000; 
  top: 0;
  background-color: $menu-bg;
}

.logo {
  padding-right: 15px;
  max-width: 400px;
  height: auto;
}

.logo-margin {
  margin-right: 14px;
}

.logo_mobile {
  @extend .logo;
  max-width: 130px;
  padding-right: 0px;
}

.menu {
  height: 100%;
}

.search-container {
  position: relative;
  background: $menu-bg-search;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  width: 40%;
  min-width: 130px;
  transition: width 0.5s ease-in-out;

  &:focus-within {
    width: 100%;
  }
}

.search {
  font-family: inherit;
  background: inherit;
  border: none;
  outline: none;
  color: $menu-base;

  width: 100%;
}
.search::placeholder {
  color: $menu-base !important;
  opacity: 0.6;
}
.burger {
  max-width: 350px;
}

.antiphishing {
  background-color: $color-antiphishing;
  font-size: 1rem;
  width: 100%;
  z-index: 0;
  text-align: center;
  &-text {
    font-size: 1.1rem;
  }
  i {
    position: absolute;
    right: 5px;
    top: -5px;
    cursor: pointer;
  }
}

.search-results {
  box-sizing: border-box;
  position: absolute;
  overflow-y: auto;
  border: 1px solid $menu-border;
  top: 100%;
  max-width: 350px;
  width: max-content;
  max-height: 500px;
  background-color: $menu-bg2;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 1;
  color: $menu-base;
  :hover {
    color: $menu-active;
  }

  > div {
    margin: 10px 10px;
  }

  > div > a {
    color: inherit;
  } 
  @media (min-width: 1199px) {
    left: 0;
  }
  @media (max-width: 460px) {
    position: fixed;
    left: 0;
    right: 0;
    max-width: none;
    width: 100%;
    top: 54px;
  }
}
::-webkit-scrollbar {
  width: 5px;
  margin-right: -5px;
  background: $menu-bg;
}
::-webkit-scrollbar-thumb {
  background: $menu-active;
  border-radius: 10px;
}
</style>
