<template>
    <div>
            <TableList ref="tablePagin" :data.sync='this.data'
                @updateContentState="this.updateContent"
                :paginationClass="'col-sm-3'"
                :sortRule="this.sort_rule"
                :head_slot="true"
                :indexes="['name', 'keyt', 'curr', 'balance', 'alias']">
                <template v-slot:head>
                    <div class="d-flex justify-content-around justify-content-lg-between align-items-center flex-wrap">
                        <div class="btn-group" role="group" aria-label="Basic outlined example">
                            <Button
                                class="btn-d-width"
                                :name="$t('views.Keyts.List.15')" :style="'padding-left: 20px; padding-right: 20px;'" 
                                v-on:click="this.changeStage(0)"
                                :type='this.of_stage === 0 ? "btn-warning" : "btn-outline-warning"'
                            />
                            <Button
                                class="btn-d-width"
                                :name="$t('views.Keyts.List.24')" :style="'padding-left: 20px; padding-right: 20px;'" 
                                v-on:click="this.changeStage(1)"
                                :type='this.of_stage === 1 ? "btn-warning" : "btn-outline-warning"'
                            />
                            <Button
                                class="btn-d-width"
                                :name="$t('views.Keyts.List.25')" :style="'padding-left: 20px; padding-right: 20px;'"  
                                v-on:click="this.changeStage(2)"
                                :type='this.of_stage === 2 ? "btn-warning" : "btn-outline-warning"'
                            />
                        </div>
                    </div>
                </template>
                <template v-slot:table>
                <div  v-if="this.content.length > 0" class="table-div">
                    <div v-for="(row, index) in this.content" :key="row.keyt"
                        class="col-12 pb-2 pt-2 d-flex flex-column align-items-center border-bottom border-1 border-grey"
                    >
                    <div class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-between" v-on:click="() => {if (row.custom !== '1') {return}; this.getVAParams(row.curr_code)}">
                        <div class="col-12 col-lg-6 d-flex flex-row align-items-center justify-content-between">
                            <div class="col-6 d-flex flex-row align-items-center mh-52">
                                <div v-if="row.image" class="item-i round-border contain c40" :class="{'mrp-10': this.mobile, 'mrp-20': !this.mobile}" :style="`background-image: url('${row.image}');`"></div>
                                <div v-else class="simp-bg k-symbol" :class="{'mrp-10': this.mobile, 'mrp-20': !this.mobile}">
                                    <span>{{row.symbol}}</span> 
                                </div> 
                                <div
                                    class="d-flex flex-column pt-0" 
                                    :style="''">
                                    <div class='href neue-roman d-flex flex-row'>
                                        <span v-if='this.k_name && row.alias' :title='row.alias'>{{this.sbstring(row.alias)}}</span>
                                        <span v-else>{{this.sbstring(row.keyt)}}</span>
                                        <i class='item-i copy pointer c18 cli_2 ml-2' @click.stop="this.$lib.copyTextS(this.k_name && row.alias ? row.alias : row.keyt)" ></i>
                                        <a v-if="row.explorer_link" :href="row.explorer_link" target="_blank"><i class='item-i c18 ml-2 pointer ext-link'></i></a>
                                    </div>
                                    <span class='grey light fs_09' :class="{'fsp12': this.mobile}" :title='row.name'>{{this.sizedName(row.name)}}</span>
                                </div>
                            </div>
                            <div v-if="row.balance.split('.')[0].length <= 9">
                                <span class='neue-roman fs_13' v-html='this.sumMask(row.balance)'></span> <span class='grey fs_08' :class="{'fsp12': this.mobile}">{{row.curr}}</span>
                            </div>
                            <Tooltip v-else 
                                :key="row.keyt" 
                                :content="this.smallSummMask(row.balance)" 
                                placement="bottom" class="p-0" 
                                :htmlContent="true"
                                :hover="true"
                                >
                                <span class='neue-roman fs_13 pointer' v-html='this.bigBalance(row.balance)'></span> <span class='grey fs_08' :class="{'fsp12': this.mobile}">{{row.curr}}</span>
                            </Tooltip>
                        </div>
                        <div class="col-12 col-lg-6 d-flex flex-row align-items-center justify-content-end justify-content-sm-between">
                            <div v-if="this.$store.state.resize.w >= 750" class="col-6">

                            </div>
                            <div class="col-12 d-flex flex-row align-items-center justify-content-end custom-d-width"
                            :class="{'mtp-12': this.$store.state.resize.w < 750}"
                            >
                                <Dropdown @click.stop
                                    mouseover_event
                                    grouped
                                    class="custom_div"
                                    :is_slot="true"
                                    :menu="this.oprationsList"
                                    :type="this.btns_text['l_' + row.keyt] == true ? 'btn-warning open_btn' : 'btn-warning'"
                                    :style="'margin-right: 20px;'" 
                                    :position="'right lg-left'"
                                    :args="row"
                                    v-on:mouseenter="this.btns_text['l_' + row.keyt] = true;" 
                                    v-on:mouseleave="this.btns_text['l_' + row.keyt] = false;"
                                >
                                    <div class="d-flex col-12 flex-row align-items-center justify-content-start justify-content-md-center">
                                        <Icon :name="'money'" :size="'c24'" :active="false" /> 
                                        <Transition @enter="this.event_enter" @leave="this.event_leave">
                                            <span v-if="this.btns_text['l_' + row.keyt] || this.$store.state.resize.w < 750"
                                                class="mlp-8  text-center" :class="{'w-100': this.$store.state.resize.w < 750}" :style="'white-space: nowrap;'">{{$t('views.Keyts.List.26')}}
                                            </span>
                                        </Transition> 
                                        </div>
                                </Dropdown>
                                <Dropdown @click.stop
                                    class="custom_div"
                                    mouseover_event
                                    grouped
                                    :menu="this.settingsList"
                                    :is_slot="true"
                                    :type="this.btns_text['r_' + row.keyt] == true ? 'btn-warning open_btn' : 'btn-warning'"
                                    :position="'right lg-left'"
                                    :args="row"
                                    v-on:mouseenter="this.btns_text['r_' + row.keyt] = true;" 
                                    v-on:mouseleave="this.btns_text['r_' + row.keyt] = false;"
                                >
                                    <div class="d-flex col-12 flex-row align-items-center justify-content-start justify-content-md-center">
                                        <Icon :name="'gears'" :size="'c24'" :active="false" />
                                        <Transition  @enter="this.event_enter" @leave="this.event_leave">
                                            <span v-if="this.btns_text['r_' + row.keyt] || this.$store.state.resize.w < 750" 
                                                class="mlp-8 text-center" :class="{'w-100': this.$store.state.resize.w < 750}"
                                                :style="'white-space: nowrap;'">
                                                {{$t('views.Keyts.List.27')}}
                                            </span>
                                        </Transition>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <Transition @before-enter="this.$lib.VAanimateOpen" @leave="this.$lib.VAanimateClose">
                            <ShowInfoVA v-if="this.vaData && this.vaData.code == row.curr_code" :vaData="this.vaData" />
                        </Transition>
                    </div>
                    </div>
                </div>
                <div v-else class='mt-2 grey text-center'>
                    <h5 v-if='this.k_name'>{{ $t('views.Keyts.List.19') }}</h5> 
                    <h5 v-else>{{ $t('views.Keyts.List.20') }}</h5>
                </div>
                </template>
                <template v-slot:buttons>
                    <div class="d-flex container-fluid flex-wrap g-3 pb-1 p-0 align-items-center justify-content-between">
                        <div class='col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start'>
                            <Button :name="this.k_name ? $t('views.Keyts.List.10') : $t('views.Keyts.List.11')" v-on:click='this.$router.push("/keyts/new")' class='' type='btn-outline-warning'/>
                        </div>
                    </div>
                </template>
        </TableList>
    </div>
</template>

<script>
import { gsap } from "gsap";
import TableList from '@/components/service/pagination/TableList';
import Dropdown from "@/components/service/Dropdown";
import Icon from "@/components/icons/Icon.vue";
import Button from '@/components/service/Button';
import ShowInfoVA from '@/components/service/ShowInfoVA.vue';
import Tooltip from '@/components/service/Tooltip';


export default {
    components: {
        Button,
        Dropdown,
        Icon,
        ShowInfoVA,
        TableList,
        Tooltip 
    },
    props: {
        data: [Array],
        of_stage: {
            default: 0,
            type: [Number, Boolean]
        }
    },
    data() {
        return {
            content: {},
            filtered: [],
            vaData: false,
            aliases: false,
            checked: false,
            boxes: {},
            of_stage: 0,
            mainChecked: false,
            categorys: [],
            btns_text: {},
            sort_rule: {
                ind: 'date_create',
                to: true,
                type: 'date'
            },
            k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
            oprationsList: [
                {
                    name: this.$t('views.Keyts.List.28'),
                    handler: (row) => {
                        this.$store.commit('MODAL', {
                            action: true,
                            type: 'PaySelect',
                            content: {
                                action: 'payIn',
                                keyt: row
                            }
                        })
                    },
                    icon: 'deposit'
                },
                {
                    name: this.$t('views.Keyts.List.29'),
                    handler: (row) => {
                        this.$store.commit('MODAL', {
                            action: true,
                            type: 'PaySelect',
                            content: {
                                action: 'payOut',
                                keyt: row
                            }
                        })
                        // this.$router.push({name: "Pay"});
                    },
                    icon: 'withdraw'
                },
                {
                    name: this.$t('views.Keyts.List.30'),
                    handler: (row) => {
                        this.$store.commit('MODAL', {
                            action: true,
                            type: 'PaySelect',
                            content: {
                                action: 'transfer',
                                keyt: row
                            }
                        })
                        // this.$router.push({name: "p2p_offers"});
                    },
                    icon: 'horizontal_arr'
                }
            ],
            settingsList: [
                {
                    name: this.$t('views.Keyts.List.31'),
                    handler: () => {
                        console.log('Скрыть');
                    },
                    disabled: true,
                    icon: 'eye-off'
                },
                {
                    name: this.$t('views.Keyts.List.32'),
                    handler: (row) => {
                        this.changeKeyt(row.keyt, row.name);
                    },
                    icon: 'edit_k'
                },
                {
                    name: this.$t('views.Keyts.List.33'),
                    handler: (row) => {
                        this.deleteKeyt(row.keyt, row.balance, row.alias)
                    },
                    icon: 'trash'
                }
            ],
        }
    },
    computed: {
        mobile () {
            return this.$store.state.isMobile;
        }
    },
    created() {
        this.getCategory();
    },
    methods: {
        sbstring(str) {
            if (this.$store.state.resize.w < 720 && str.length > 7) {
                return this.$lib.getTokenString(str, 4);
            } else if (this.$store.state.resize.w < 1200 && str.length > 20) {
                return this.$lib.getTokenString(str, 8);
            } else if (str.length > 30) {
                return this.$lib.getTokenString(str, 12);
            } 
            return str;
        },
        changeStage(arg) {
            this.of_stage = arg;
            this.$emit('updateStage', arg);
        },
        event_enter(el) {
            gsap.fromTo(el, { opacity: 0, width: 0 }, { opacity: 1, width: "auto", duration: 0.4 });
        },
        event_leave(el, done) {
            gsap.fromTo(el, { opacity: 1 }, { opacity: 0.1, width: 0, duration: 0.4, onComplete: done });
        },
        sizedName(str) {
        if (!str) {
            return "";
        } else if (this.$store.state.resize.w < 720 && str.length > 20) {
            return str.substring(0, 20) + "...";;
        } else if (this.$store.state.resize.w < 1200 && str.length > 20) {
            return this.$lib.getTokenString(str, 8);
        } else if (str.length > 34) {
            return str.substring(0, 35) + "...";
        }
        return str;
        },
        sumMask(sum) {
            let arr = sum.split('.');
            if (arr[1]) {
                return `<span  class='${this.mobile ? 'fsp16' : ''}'>${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}</span><span class='light fs_09 ${this.mobile ? 'fsp12' : ''}'>.${arr[1]}</span>`
            } else {
                return `<span  class='${this.mobile ? 'fsp16' : ''}'>${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}</span>`
            }
        },
        smallSummMask(sum) {
            let arr = sum.split('.');
            if (arr[1]) {
                return `${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}.${arr[1]}`;
            } else {
                return `${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}`;
            }
        },
        bigBalance(sum) {
            let arr = sum.split('.');
            if (arr[0].length > 9) {
                let humVal = this.$lib.bignumberToHumanValue(arr[0]);
                return `<span  class='${this.mobile ? 'fsp16' : ''}'>${humVal[0]} ${humVal[1]}</span>`
            } else {
                return this.sumMask(sum);
            }
        },
        getCategory() {
            this.axios.post('GetKeywords', {group: '115'}).then(res => {this.categorys = res.data.body});
        },
        getVAParams(vaCode) {
            if (this.vaData && vaCode == this.vaData.code) {
                this.vaData = false
                return;
            }
            this.vaData = false
            this.axios.post('GetCustomCurrParams', {data: {code: vaCode}}).then(res => {
                if (res.data.body.result === '0') {

                    const linksDataArray = [
                        {code: '0', icon: 'va-host', text: this.$t('views.ShowInfoVA.21')},
                        {code: '1', icon: 'va-white-book', text: this.$t('views.ShowInfoVA.22')},
                        {code: '2', icon: 'va-mail', text: this.$t('views.ShowInfoVA.23')},
                        {code: '3', icon: 'va-telegram', text: 'Telegram'},
                        {code: '4', icon: 'va-twitter', text: 'Twitter'},
                        {code: '5', icon: 'va-facebook', text: 'Facebook'},
                        {code: '6'}
                    ];

                    const vaDataCurr = res.data.body.advanced;

                    const category = this.categorys.find(item => item.code == vaDataCurr.category);
                    vaDataCurr.category = category.value;

                    const name = vaDataCurr.name_en ? vaDataCurr.name_en : vaDataCurr.name_ru;
                    vaDataCurr.name = name;
                    let tmp = {}
                    tmp.curr = vaDataCurr;
                    const links = res.data.body.tables[1].colvalues;
                    const linksResult = linksDataArray.filter((link) => links.some((item) => item.code == link.code));

                    linksResult.forEach((link) => {
                        const linkItem = links.find(item => item.code == link.code);
                        link.value = linkItem.value;
                    });
                    tmp.links = linksResult;
                    tmp.code = vaCode;

                    this.vaData = tmp;
                    
                }
            })
        },
        updateContent(arg) {
            if (typeof arg == 'undefined') {
                arg = {}
            } else {
                this.aliases = false;
                arg.forEach(e => {
                    if (e.alias != '') {
                        this.aliases = true;
                    }
                });
            }
            this.content = arg;
            console.log('updateContent List page', this.content);
        },
        alltoDel(e) {
            console.log(e.checked)
            if (e.checked) {
                this.content.forEach(e => {
                    if (e.balance == '0.00') {
                        this.boxes[e.keyt] = e.keyt
                    }
                })
                this.checked = true
            } else {
                this.checked = false
                this.boxes = {};
            }
        },
        toDel(e) {
            if (e.checked) {
                // this.boxes.push(e.value);
                this.boxes[e.value] = e.value
            } else {
                // this.boxes.splice(this.boxes.indexOf(e.value), 1);
                delete this.boxes[e.value]
            }
        },
        renameKeyt(keyt, obj) {
            let k = false;
            this.data.forEach(e => {
                if (e.keyt == keyt) {
                    if (obj.v) {
                        e.hide = e.hide == '1' ? '0' : '1';
                    }
                    if (obj.name) {
                        e.name = obj.name;
                    }
                    k = e;
                }
            });
            let d = {
                keyt : k.keyt,
		        name : k.name,
		        hide : k.hide
            }
            this.axios.post('RenameKeyt', {data: d})
        },
        changeKeyt(keyt, name) {
            this.$store.commit('MODAL', {
                                        action: true,
                                        type: 'k_rename',
                                        content: {
                                            text: this.k_name ? this.$t('views.Keyts.List.21') : this.$t('views.Keyts.List.16'),
                                            keyt: keyt,
                                            name: name,
                                            callback: this.renameKeyt
                                        },
                                    }
                                );
        },
        delAll() {
            let data = [];
            for (let key in this.boxes) {
                data.push({'keyt': this.boxes[key]})
            }
            this.boxes = {};
            this.$store.commit('MODAL', {
                                        action: true,
                                        type: 'confirm',
                                        content: {
                                            text: this.k_name ? this.$t('views.Keyts.List.22') : this.$t('views.Keyts.List.17'),
                                            callback: () => {
                                                this.axios.post('DeleteKeyts', {data: data}).then(res => {
    
                                                })
                                            }
                                        },
                                    }
                                );
        },
        deleteKeyt(keyt, balance, alias) {
            if (balance != '0.00') {
                return
            }
            let c = ` ${(alias && this.k_name) ? alias : keyt}?`
            console.log(c)
            this.$store.commit('MODAL', {
                                        action: true,
                                        type: 'confirm',
                                        content: {
                                            text: this.k_name ? this.$t('views.Keyts.List.23') + c : this.$t('views.Keyts.List.18') + c,
                                            keyt: keyt,
                                            callback: () => {
                                                this.axios.post('DeleteKeyt', {data: {keyt: keyt}})
                                            }
                                        },
                                    }
                                );
        },
    },
    
}
</script>

<style lang="scss" scoped>
.mh-52 {
    min-height: 52px;
}
.simp-bg {
  background-color: $color-href;
  border: 1px solid $color-href;
  color: white;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
}
.custom-d-width {
    @media (min-width: 767px) {
        max-width: 195px;
    }
    @media (max-width: 767px) {
        width: 100% !important;
        .custom_div {
            width: 49% !important;
        }
    } 
}
.btn-d-width {
    @media (min-width: 500px) { 
        min-width: 130px;
    }
}
</style>