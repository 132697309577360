<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.$t('views.ChoiseColumns.1') }}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div>
            <div v-for="(item, index) in this.columns" :key="index" class="d-flex">
                <SwitchSimple 
                    class='p-0 col-12 pointer align-items-start mb-2'
                    :class="!this.columnsChoised[index].value ? 'neue-roman' : ''"
                    :id='"check_column_" + this.columnsChoised[index].id'
                    :label="this.columnsChoised[index].text_name ? this.columnsChoised[index].text_name : this.columnsChoised[index].name"
                    label_style="font-size: 1rem; cursor: pointer; line-height: 1rem"
                    :isSmall="true"
                    :disabled="this.isItemDisabled(this.columnsChoised[index].key_name)"
                    type='form-check-mark'
                    :dataValue="this.columnsChoised[index].value"
                    @updateParentState="this.updateState"
                />
            </div>
        </div>
        <div class="col-12 d-flex justify-content-end align-items-center mt-4 m-auto">
            <div class="col-12 col-md-3 d-flex flex-column-reverse flex-md-row align-items-center justify-content-between">
                <Button
                    :name="this.$t('views.ChoiseColumns.2')"
                    :class='{"mr-1" : this.$store.state.resize.w >= 768, "col-12" : this.$store.state.resize.w < 768}'
                    type='btn-outline-warning'
                    @click="this.reset()"
                    :style='"min-width: 108px;"'
                />
                <Button
                    :name="this.$t('views.ATM.12')"
                    :class='{"margin-bottom" : this.$store.state.resize.w < 768, "col-12" : this.$store.state.resize.w < 768}'
                    type='btn-warning'
                    @click="this.setColumns()"
                    :style='"min-width: 108px;"'
                />
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import SwitchSimple from '@/components/service/SwitchSimple';

export default {
    components: {
        Button,
        SwitchSimple
    },
    props: {
        close: [Function],
    },
    data() {
        return {
            columns: [],
            columnsChoised: [],
            disabledColumns: this.$store.state.modal.content.disabledColumns,
            initialColumns: this.$store.state.modal.content.initialColumns,
        }
    },
    methods: {
        getColumnNumber(input) {
            const regex = /check_column_(\d+)/;
            const match = input.match(regex);

            if (match) {
                return match[1];
            } else {
                return null;
            }
        },
        updateState(arg) {
            const id = this.getColumnNumber(arg.id);
            const column = this.columnsChoised.find(item => item.id == id);
            column.value = arg.value;
        },
        reset() {
            this.$store.state.modal.content.reset();
            this.columns = [];
            this.columns = [...this.initialColumns];
            this.columnsChoised = JSON.parse(JSON.stringify(this.columns));
        },
        setColumns() {
            this.$store.state.modal.content.updateColumns(this.columnsChoised);
            this.close();
        },
        isItemDisabled(key_name) {
            return this.disabledColumns.includes(key_name);
        }
    },
    created() {
        this.columns = this.$store.state.modal.content.columns;
        this.columnsChoised = JSON.parse(JSON.stringify(this.columns));
    }
}
</script>

<style lang="scss" scoped>
.margin-bottom {
    margin-bottom: 20px;
}
</style>