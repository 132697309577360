<template>
    <div class="custom_modal_wrap">
        <div class='d-flex justify-content-between align-items-center neue mbp-20'>
            <h4 class="mb-0">{{ this.actions[this.act]['title'] }}</h4>
            <i class='big item-i x_red pointer c24 contain' v-on:click='this.close'></i>
        </div>
        <div v-if="this.$store.state.isMobile"
            class='container-fluid mtp-12 p-0 g-3 d-flex flex-column'>
            <div v-for="(item, index) in this.actions[this.act]['list']"
             :key="index" 
             class="d-flex flex-row align-items-center justify-content-start pointer mob-card"
            >
                <Icon :name="item.icon" :size="'c40'" :preset="true" :active="true" class="mrp-10"/>
                <div class="d-flex flex-column align-items-start justify-content-center">
                    <span class="fsp16 neue">{{ item.name }}</span>
                    <span class="fsp12 light">{{ item.desc }}</span>
                </div>
            </div>
        </div>
        <div v-else class='container-fluid mt-1 p-0 g-3 d-flex flex-row flex-wrap align-items-stretch gap20'>
            <div v-for="(item, index) in this.actions[this.act]['list']"
             :key="index" 
             v-on:mouseover="this.hovered = index"
             v-on:mouseleave="this.hovered = false"
             v-on:click="() => {item.handler(); this.close()}"
             class="plp-20 prp-20 ptp-10 pbp-10 card"
             >
                <div v-if="this.hovered === index" class="h100 d-flex flex-column align-items-center justify-content-center text-center">
                    <span class="fsp20 neue">{{ item.name }}</span>
                    <span class="fsp14 light">{{ item.desc }}</span>
                </div>
                <div v-else class="h100 d-flex flex-column align-items-center justify-content-center text-center">
                    <Icon :name="item.icon" :size="'c60'" :preset="true" :active="true" />
                    <span class="fsp20 neue">{{ item.name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";

export default {
    props: {
        close: [Function],
    }, 
    components: {
        Icon
    },
    data () {
        return {
            content: this.$store.state.modal.content,
            act: this.$store.state.modal.content.action, 
            hovered: false,
            actions: {
                payIn: {
                    title: this.$t('components.service.modal.PaySelect.1'),
                    list: [
                        {
                            name: this.$t('components.service.modal.PaySelect.2'),
                            desc: this.$t('components.service.modal.PaySelect.18'),
                            handler: () => {
                                let params = {
                                    keyt: this.$store.state.modal.content.keyt.keyt,
                                };
                                this.$router.push({name: 'Payin', query: params})
                            },
                            icon: 'cryptocurr'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.4'),
                            desc: this.$t('components.service.modal.PaySelect.19'),
                            handler: () => {
                                this.$router.push({name: 'p2p_offers'})
                            },
                            icon: 'p2p'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.5'),
                            desc: this.$t('components.service.modal.PaySelect.20'),
                            handler: () => {
                                let params = {
                                    keyt: this.$store.state.modal.content.keyt.keyt,
                                };
                                this.$router.push({name: 'CreateEcheck', query: params})
                            },
                            icon: 'echeck'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.6'),
                            desc: this.$t('components.service.modal.PaySelect.21'),
                            handler: () => {
                                let params = {
                                    stage: 0
                                };
                                this.$router.push({name: 'Templates', query: params})
                            },
                            icon: 'template'
                        },
                    ]
                },
                payOut: {
                    title: this.$t('components.service.modal.PaySelect.7'),
                    list: [
                        {
                            name: this.$t('components.service.modal.PaySelect.8'),
                            desc: this.$t('components.service.modal.PaySelect.22'),
                            handler: () => {
                                this.$router.push({name: 'Pay'})
                            },
                            icon: 'cryptocurr'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.9'),
                            desc: this.$t('components.service.modal.PaySelect.23'),
                            handler: () => {
                                this.$router.push({name: 'Pay'})
                            },
                            icon: 'pay'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.10'),
                            desc: this.$t('components.service.modal.PaySelect.24'),
                            handler: () => {
                                this.$router.push('/masspayment/new')
                            },
                            icon: 'masspay'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.11'),
                            desc: this.$t('components.service.modal.PaySelect.25'),
                            handler: () => {
                                this.$router.push({name: 'p2p_offers'})
                            },
                            icon: 'p2p'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.12'),
                            desc: this.$t('components.service.modal.PaySelect.26'),
                            handler: () => {
                                let params = {
                                    stage: 2
                                };
                                this.$router.push({name: 'Templates', query: params})
                            },
                            icon: 'template'
                        },
                    ]
                },
                transfer: {
                    title: this.$t('components.service.modal.PaySelect.13'),
                    list: [
                        {
                            name: this.$t('components.service.modal.PaySelect.14'),
                            desc: this.$t('components.service.modal.PaySelect.27'),
                            handler: () => {
                                this.$router.push({name: 'Stock'})
                            },
                            icon: 'exchange'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.15'),
                            desc: this.$t('components.service.modal.PaySelect.28'),
                            handler: () => {
                                this.$router.push({name: 'Transfer_id'})
                            },
                            icon: 'user'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.16'),
                            desc: this.$t('components.service.modal.PaySelect.29'),
                            handler: () => {
                                let params = {
                                    keyt: this.$store.state.modal.content.keyt.keyt,
                                };
                                this.$router.push({name: 'Transfer_self', query: params})
                            },
                            icon: 'horizontal_arr'
                        },
                        {
                            name: this.$t('components.service.modal.PaySelect.17'),
                            desc: this.$t('components.service.modal.PaySelect.30'),
                            handler: () => {
                                let params = {
                                    stage: 1
                                };
                                this.$router.push({name: 'Templates', query: params})
                            },
                            icon: 'template'
                        },
                    ]
                },
            }
        }
    },
    methods: {

    },
    mounted() {
        console.log('content', this.content);
    }
}
</script>

<style lang="scss" scoped>
.card {
    border-radius: 15px;
    border: 1px solid $menu-bg-active;
    min-height: 180px;
    width: 100%;
    cursor: pointer;
    // @media (min-width: 1920px) {
        max-width: 213px;
    // }

}
.custom_modal_wrap {
    @media  screen and (min-width: 1200px) {
        width: 913px;
    }
    @media  screen and (min-width: 975px) and (max-width: 1199px) {
        width: 680px;
    }
}
.mob-card:not(:last-child) {
    margin-bottom: 20px;
}

.gap20 {
    gap: 20px;
}
</style>