<template>
    <div v-if="this.grouped" ref='d_down'  :style="'z-index:' + this.top_index + ';'">
        <a v-if="this.is_slot" class="btn dropdown"  :id="'c-dd-'+this.id" :style="this.d_down_width ? 'width: '+ this.d_down_width +'px;' : 'width: 100%;'" 
        :class="[this.active ? 'open-top' : '', this.type]" 
        v-on:click="(e) => {this.active ? this.close(e) : this.open()}"
        v-on:mouseover="() => {return this.mouseover_event ? this.open() : false}" role="button">
            <slot></slot>
            <transition @enter="enter" @leave="leave">
            <div v-if="this.active" ref="sub_down"  :style='"max-height: " + this.listHeight + "px; top: 2.4rem; left: -1px; right: 0px;" + this.opt_style'
            class="btn-group-vertical group-open-up">
            <a v-for="(row, index) in this.menu" 
                v-on:mouseover="() => {this.active_btn = index;}"  
                v-on:mouseleave="() => {this.active_btn = false;}"
                :key="index" class="btn btn-outline-warning d-flex flex-row align-item-center justify-content-start open-href" 
                :id="'c-dd-i-'+index" 
                :style="this.sub_down_width ? 'width: '+ this.sub_down_width +'px; ' + this.opt_style : this.opt_style" 
                :class="{'disabled': row.disabled}" 
                v-on:click="(e) => {row.handler(this.args); this.close(e);}">
                <Icon v-if="row.icon" class="mr-1" :name="row.icon" :size="'c24'" :active="index === this.active_btn" />
                <span>{{row.name}}</span>
            </a>
            </div>
            </transition>
        </a>
    </div>
    <div v-else ref='d_down' class="dropdown">
        <a v-if="this.is_slot" class="btn" :id="'c-dd-'+this.id" :class="this.type"
        v-on:click="this.open" v-on:mouseover="() => {return this.mouseover_event ? this.open() : false}" role="button">
            <slot></slot>
        </a>
        <a v-else class="btn dropdown-toggle" :class="this.type" style="min-height: 38px;" :id="'c-dd-'+this.id" 
        v-on:click="this.open" v-on:mouseover="() => {return this.mouseover_event ? this.open() : false}" role="button">
            {{this.label}}
        </a> 
        <transition @enter="enter" @leave="leave">
        <div v-if="this.active"  :style='"max-height: " + this.listHeight + "px; top: 2.17rem; " + this.opt_style' :class="[this.transformUp ? 'open-up' : '', this.position]" 
        class="btn-group-vertical">
            <a v-for="(row, index) in this.menu" 
                v-on:mouseover="() => {this.active_btn = index;}"  
                v-on:mouseleave="() => {this.active_btn = false;}"
                :key="index" class="btn btn-outline-warning d-flex flex-row align-item-center justify-content-start" 
                :id="'c-dd-i-'+index" :style="this.opt_style" :class="{'disabled': row.disabled}" 
                v-on:click="(e) => {row.handler(this.args); this.close(e);}">
                <Icon v-if="row.icon" class="mr-1" :name="row.icon" :size="'c24'" :active="index === this.active_btn" />
                <span>{{row.name}}</span>
            </a>
        </div>
        </transition>
    </div>
</template> 

<script>
import { gsap } from "gsap"; 
import Icon from "@/components/icons/Icon.vue";

export default {
    components: {
        Icon
    },
    props: { 
        menu: [Array],
        label: {
            default: '',
            type: [String, Boolean]
        },
        is_slot: {
            default: false,
            type: [Boolean]
        },
        id: {
            default: 'def',
            type: [String, Boolean]
        },
        position: {
            default: '',
            type: [String, Boolean]
        },
        type: {
            default: 'btn-outline-warning',
            type: [String, Boolean]
        },
        opt_style: {
            default: '',
            type: [String, Boolean]
        },
        args: {
            default: false,
            type: [Object, Boolean]
        },
        grouped: [Boolean],
        mouseover_event: [Boolean]
    },
    data() {
        return {
            active: false,
            transformUp: false,
            active_btn: false,
            top_index: 100,
            sub_down_width: false,
            d_down_width: false,
        }
    },
    mounted() {
    },
    methods: {
        open() {
            this.listHeight = this.getHeight();
            console.log(this.listHeight)
            this.transformUp = Number(this.listHeight) < 70
            if (this.transformUp) {
                this.listHeight = '200'
            }
            this.active = true;
            document.querySelector('body').addEventListener('mouseover', this.close);
            this.top_index = 101;
        },
        close(e) {
            if (!this.$el.contains(e.target) || e.type == 'click') {
                this.active = false;
                this.top_index = 100;
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        enter(el, done) {
            gsap.fromTo(el, { opacity: 0.1, y: '-50%',  scaleY: 0.1 }, { opacity: 1, y: '0%', scaleY: 1, duration: 0.3, onComplete: (done) => {
                if (!this.$refs.sub_down) {
                    return;
                }
                console.log('d', this.$refs.d_down.clientWidth)
                console.log('sub', this.$refs.sub_down.clientWidth)
                if (this.$refs.sub_down.clientWidth > this.$refs.d_down.clientWidth) {
                    this.d_down_width = this.$refs.sub_down.clientWidth;
                    this.sub_down_width = this.$refs.sub_down.clientWidth;
                } else {
                    this.d_down_width = this.$refs.d_down.clientWidth;
                    this.sub_down_width = this.$refs.d_down.clientWidth;
                }


            } });
        },
        leave(el, done) {
            this.d_down_width = false;
                this.sub_down_width = false;
            gsap.fromTo(el, { y: '0%', scaleY: 1, opacity: 1 }, { y: '-50%',  scaleY: 0.1, opacity: 0.1, duration: 0.3, top: '100%', onComplete: done});
        },
        getHeight() {
            let list = this.$refs.d_down.getBoundingClientRect();
            // let box = document.querySelector('#app').getBoundingClientRect();
            let box = {height: window.innerHeight};
            return String((box.height + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
    },
}
</script>

<style lang="scss" scoped>
.open-up {
  border-radius: 0.25rem;
  border-top: 1px solid #ced4da;
}
.group-open-up {
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: 1px solid #ced4da;
}
.open-top {
    border-radius: 0.25rem 0.25rem 0 0 !important;
}
.open-href {
    border-radius: 0 0 0.25rem 0.25rem !important;
}
</style>