<template>
    <div class="row container-fluid mt-1 g-3 p-0 align-items-center input">
        <div class="col-12 col-md-3 p-0">
            <label :for="id" class="col-form-label input-label">{{this.label}}</label>
        </div>
        <div class="col-12 col-md-9 p-0 input-wrapper" :class="'m-0' ? this.$store.state.resize.w < 768 : ''">
            <InputDateSimple
                class="col-12"
                :id="id"
                :dataValue='this.dataValue'
                :expFormat="this.expFormat"
                :disabled="this.disabled"
                :type='this.type'
                :newDate="this.newDate"
                @updateParentState="this.setValue"
                :min="this.min"
                :max="this.max"
                :enableSeconds="this.enableSeconds"
                :clearable="this.clearable"
                :autoApply="this.autoApply"
            />
            <div class="d-flex flex-row align-items-center justify-content-end">
                <Tooltip v-if="this.hint" :content='this.hint'/>
                <div v-else-if="this.unvisible" class="mw34"></div>
                <Tooltip
                    v-if="this.verification"
                    :custom="'item-i check'"
                    :content="$t('views.InfoReg.3')"
                />
                <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
                <Tooltip v-if="this.unvisible" :content="$t('views.InfoReg.4')" :htmlContent="true">
                    <SwitchSimple
                        :id="id"
                        :dataValue='this.unvisibleValue'
                        :disabled="this.is_public == '2' ? true : false"
                        :type="'sm'"
                        class="ml-1"
                        @updateParentState="this.setUnvisibleValue"
                    />
                </Tooltip>
            </div>
        </div>
    </div>
</template> 

<script>
import InputDateSimple from '@/components/service/InputDateSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Tooltip,
        InputDateSimple,
        SwitchSimple
    },
    data() {
        return {
            value: this.dataValue ? this.dataValue : false,
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        id: [String, Boolean],
        label: [String],
        hint: [String],
        dataValue: [String, Boolean],
        verification: [String, Boolean],
        type: {
            default: 'date',
            type: [String, Boolean]
        },
        disabled: [Boolean],
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        expFormat: {
            default: false,
            type: [Boolean]
        },
        is_public: [Boolean, String],
        newDate: {
            default: false,
            type: [Boolean]
        },
        min: {
            default: new Date('1900'),
            type: [Date, Boolean, String]
        },
        max: {
            default: new Date('2100'),
            type: [Date, Boolean, String]
        },
        enableSeconds: {
            default: false,
            type: [Boolean]
        },
        clearable: {
            default: false,
            type: [Boolean]
        },
        autoApply: {
            default: true,
            type: [Boolean]
        },
        ignoreTimeValidation: {
            default: false,
            type: [Boolean]
        }
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
    },
    mounted() {
        console.log('mounted inputDate dataValue', this.dataValue);
    }
}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}
.mt-1 {
    margin: 0 !important;
}
.require {
    color: red;
}
</style>