import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Unexpected from '../views/Unexpected.vue'
import Test from '../views/Test.vue'

const routes = [
  {
    path: '/',
    name: 'Home', 
    component: Home
  },
  {
    path: '/va/new',
    name: 'NewVA',
    component: () => import('../views/VA/NewVa.vue'),
  },
  {
    path: '/va/list',
    name: 'VA_list',
    component: () => import('../views/VA/ListVa.vue'),
  },
  {
    path: '/va/code/:code?',
    name: 'CodeVA',
    component: () => import('../views/VA/CodeVa.vue'),
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import('../views/Safety/Safety.vue'),
    children: [
      {
        path: '/safety/pass',
        component: () => import('../views/Safety/Password.vue'),
      },
      {
        path: '/safety/pay_pass',
        component: () => import('../views/Safety/PayPassword.vue'),
      },
      {
        path: '/safety/antiphishing',
        component: () => import('../views/Safety/Antiphishing.vue'),
      },
      {
        path: '/safety/magic',
        component: () => import('../views/Safety/Magic.vue'),
      },
      {
        path: '/safety/secret_question',
        component: () => import('../views/Safety/SecretQuestion.vue'),
      },
      {
        path: '/safety/ip',
        component: () => import('../views/Safety/IP.vue'),
      },
      {
        path: '/safety/onekey',
        component: () => import('../views/Safety/Onekey.vue'),
      },
    ],
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue'),
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue'),
  },
  {
    path: '/return',
    name: 'Return',
    component: () => import('../views/Return.vue'),
  },
  {
    path: '/stock',
    name: 'Stock',
    component: () => import('../views/Stock/StockMarket.vue'),
  },
  {
    path: '/stock/order-list',
    name: 'Order_list',
    component: () => import('../views/Stock/OrderList.vue')
  },
  {
    path: '/make_good.php',
    name: 'Home_dev',
    component: Home,
  },
  {
    path: '/keyts',
    name: 'Keyts',
    component: () => import('../views/Keyts.vue'),
    children: [
      {
        path: '/keyts/new',
        component: () => import('../views/Keyts/New.vue'),
      },
      {
        path: '/keyts/list',
        component: () => import('../views/Keyts/List.vue'),
      },
    ]
  },
  {
    path: '/masspayment',
    name: 'Masspayment',
    component: () => import('../views/Masspayment.vue'),
    children: [
      {
        path: '/masspayment/new',
        component: () => import('../views/Masspayment/New.vue'),
      },
      {
        path: '/masspayment/list',
        component: () => import('../views/Masspayment/List.vue'),
      },
      {
        path: '/masspayment_info',
        component: () => import('../views/Masspayment/Info.vue'),
      },
    ]
  },
  {
    path: '/p2p/offers',
    name: 'p2p_offers',
    component: () => import('../views/p2p/offers.vue'),
  },
  {
    path: '/p2p/swaps',
    name: 'p2p_swaps',
    component: () => import('../views/p2p/swaps.vue'),
  },
  {
    path: '/p2p/active_swaps',
    name: 'p2p_active_swaps',
    component: () => import('../views/p2p/active_swaps.vue'),
  },
  {
    path: '/p2p/new',
    name: 'p2p_new',
    component: () => import('../views/p2p/new.vue'),
  },
  {
    path: '/p2p/single_swap',
    name: 'p2p_single_swap',
    component: () => import('../views/p2p/single_swap.vue'),
  },
  {
    path: '/p2p/external_curr',
    name: 'p2p_external_curr',
    component: () => import('../views/p2p/ExternalCurrencies.vue'),
  },
  {
    path: '/transfer_id',
    name: 'Transfer_id',
    component: () => import('../views/Transfer.vue'),
  },
  {
    path: '/transfer_self',
    name: 'Transfer_self',
    component: () => import('../views/TransferSelf.vue'),
  },
  {
    path: '/payin',
    name: 'Payin',
    component: () => import('../views/Payin.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/info_reg',
    name: 'Info_reg',
    component: () => import('../views/InfoReg.vue'),
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/Support.vue'),
  },
  {
    path: '/support_new',
    name: 'SupportNew',
    component: () => import('../views/SupportNew.vue'),
  },
  {
    path: '/notify',
    name: 'Notify',
    component: () => import('../views/Notify.vue'),
  },
  {
    path: '/templates',
    name: 'Templates',
    component: () => import('../views/Templates.vue'),
  },
  {
    path: '/corr_info',
    name: 'Corr_info',
    component: () => import('../views/CorrInfo.vue'),
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: () => import('../views/Merchant.vue'),
  },
  {
    path: '/merchant/new',
    name: 'Merchant_new',
    component: () => import('../views/Merchant/New.vue'),
  },
  {
    path: '/merchant/invoices',
    name: 'Invoices',
    component: () => import('../views/Merchant/Invoices.vue'),
  },
  {
    path: '/ATM/atm',
    name: 'ATM',
    component: () => import('../views/ATM/ATM.vue'),
  },
  {
    path: '/ATM/some_atm',
    name: 'Some_atm',
    component: () => import('../views/ATM/SomeATM.vue'),
  },
  {
    path: '/ATM/atm_devices',
    name: 'ATM_Devices',
    component: () => import('../views/ATM/ATMDevices.vue'),
  },
  {
    path: '/atm_auth',
    name: 'ATM_AUTH',
    component: () => import('../views/ATMauth.vue'),
  },
  {
    path: '/ATM/atm_incass',
    name: 'ATM_INCASS',
    component: () => import('../views/ATM/ATMIncass.vue'),
  },
  {
    path: '/ATM/atm_incass_item',
    name: 'ATM_INCASS_ITEM',
    component: () => import('../views/ATM/ATMInkassItem.vue'),
  },
  {
    path: '/ATM/atm_incass_balance',
    name: 'ATM_INCASS_BALANCE',
    component: () => import('../views/ATM/ATMIncassBalance.vue'),
  },
  {
    path: '/echeck',
    name: 'Echeck',
    component: () => import('../views/Echeck/List.vue'),
  },
  {
    path: '/create_echeck',
    name: 'CreateEcheck',
    component: () => import('../views/Echeck/New.vue'),
  },
  {
    path: '/activate_echeck',
    name: 'ActivateEcheck',
    component: () => import('../views/Echeck/Activate.vue'),
  },
  {
    path: '/free_reports',
    name: 'freeReports',
    component: () => import('../views/freeReports/freeReports.vue'),
  },
  {
    path: '/list_rates',
    name: 'ListRates',
    component: () => import('../views/ListRates.vue'),
  },
  {
    path: '/rate_item',
    name: 'RateItem',
    component: () => import('../views/RateItem.vue'),
  },
  {
    path: '/docs',
    name: 'Docs',
    component: () => import('../views/Docs/DocsHome.vue'),
    children: [
      {
        path: '/docs/merchant',
        component: () => import('../views/Docs/Merchant.vue'),
      },
      {
        path: '/docs/widget',
        component: () => import('../views/Docs/Widget.vue'),
      },
    ]
  },
  { path: '/:pathMatch(.*)*',
    component: Unexpected 
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
