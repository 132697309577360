<template>
  <div class='main-title container-xl container-fluid pb-5'>
    <div class='d-flex p-0 g-3 align-items-top justify-content-between flex-column flex-lg-row'>
      <div class="wh-37 col-md-12">
        <div class='d-flex p-0 g-3 align-items-end mb1r'>
          <h4 class='mb-0 neue-roman'>{{ $t('views.Home.1') }}</h4>
          <span v-if="!this.crypto" class='href pl-2 fs_09'>{{ $t('views.Home.2') }}</span>
        </div>
        <div class='height_fix content p-3 d-flex flex-column justify-content-between' v-if="this.$store.state.userData.passport_serial">
          <div v-if="!this.crypto" class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.1') }}</span> <span class='fs_09 neue-roman href d-flex flex-row align-items-center pl-2 col-7 text-start'>{{this.$store.state.userData.passport_serial[0]}}<i class='item-i h20 ext-link'></i></span>  
          </div>
          <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.3') }}</span> <span class='fs_09 neue d-flex flex-row align-items-center pl-2 col-7 text-start pointer cli_2' data-clipboard-target="#cli_h1">
              {{this.$store.state.userData.id}}
              <i class='item-i h20 copy'></i>
              <input type='hidden' id='cli_h1' :value="this.$store.state.userData.id"/>
            </span>  
          </div>
          <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.4') }}</span> <span class='fs_09 neue-roman pl-2 col-7 text-start'>{{this.$store.state.userData.pass.date_reg}}</span>  
          </div>
          <div v-if="this.$store.state.userData.last_au.last_autorization_date" class='d-flex p-0 g-3 align-items-center justify-content-between'>
            <span class='col-5 light fs_08'>{{ $t('views.Home.5') }} </span> 
              <span class='fs_09 neue-roman pl-2 col-7 text-start'>{{this.$store.state.userData.last_au.last_autorization_date}} <br>
                <div class="d-flex flex-row align-items-start">
                  <span class="mr-1">
                    {{ $t('views.Home.6') }}
                  </span>
                  <span class="break-all">
                    {{this.$store.state.userData.last_au.last_autorization_ip}}
                  </span>
                </div>
              </span>
          </div>
        </div>
      </div>
      <div v-if="this.currs" class="wh-60 col-md-12">
        <div class='d-flex p-0 g-3 align-items-center justify-content-between'>
          <div class='d-flex p-0 g-3 align-items-end flex-row justify-content-between mb1r'>
            <h4 class='mb-0 neue-roman'>{{ $t('views.Home.25') }}</h4>
          </div>
        </div>
        <div class='content height_fix p-3 d-flex flex-column justify-content-between'>
          <div class="d-flex col-12 flex-column flex-md-row justify-content-between align-items-start">
            <h4 class="grey neue-roman fsp20 mbp-20 col-4">
              {{ this.stage_title[this.of_stage] }}
            </h4>
            <div v-if="this.selected_curr && this.$store.state.resize.w > 750" class="d-flex col-8 flex-row flex-wrap justify-content-end align-items-center">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <Button :name="''" class="cell-btn" :class="{'open_btn': this.btns_text['l_1']}" v-on:click='this.$router.push("/pay")' type='btn-warning'
                  v-on:mouseenter="this.btns_text['l_1'] = true;" 
                  v-on:mouseleave="this.btns_text['l_1'] = false;"
                >
                  <IconC :name="'withdraw'" :color="{0: '#FFFFFF', 1: '#FFA234', 2: '#FFA234'}" :size="'c24'" :active="false" />
                  <Transition @enter="this.event_enter" @leave="this.event_leave">
                    <span  v-if="this.btns_text['l_1']"
                      class="mlp-8" :style="'white-space: nowrap;'">{{ this.$t('views.Home.26') }}
                    </span>
                  </Transition> 
                </Button> 
                <Button :name="''" class="cell-btn" :class="{'open_btn': this.btns_text['l_2']}" v-on:click='() => {this.PayIn(this.selected_curr.keyt)}' type='btn-warning'
                  v-on:mouseenter="this.btns_text['l_2'] = true;" 
                  v-on:mouseleave="this.btns_text['l_2'] = false;"
                >
                  <IconC :name="'deposit'" :color="{0: '#FFFFFF', 1: '#FFA234', 2: '#FFA234'}" :size="'c24'" :active="false" />
                  <Transition @enter="this.event_enter" @leave="this.event_leave">
                    <span v-if="this.btns_text['l_2']"
                      class="mlp-8" :style="'white-space: nowrap;'">{{ this.$t('views.Home.27') }}
                    </span>
                  </Transition> 
                </Button> 
                <Button :name="''" class="cell-btn" :class="{'open_btn': this.btns_text['l_3']}"  v-on:click='this.$router.push("/p2p/offers")' type='btn-warning'
                  v-on:mouseenter="this.btns_text['l_3'] = true;" 
                  v-on:mouseleave="this.btns_text['l_3'] = false;"
                >
                  <IconC :name="'horizontal_arr'" :color="{0: '#FFA234', 1: '#FFFFFF'}" :size="'c24'" :active="false" />
                  <Transition @enter="this.event_enter" @leave="this.event_leave">
                    <span v-if="this.btns_text['l_3']"
                      class="mlp-8" :style="'white-space: nowrap;'">{{ this.$t('views.Home.28') }}
                    </span>
                  </Transition> 
                </Button> 
                <Button :name="''" class="cell-btn" :class="{'open_btn': this.btns_text['l_4']}" v-on:click='this.$router.push("/transfer")' type='btn-warning'
                  v-on:mouseenter="this.btns_text['l_4'] = true;" 
                  v-on:mouseleave="this.btns_text['l_4'] = false;"
                >
                  <IconC :name="'user'" :color="{0: '#FFA234', 1: '#FFFFFF'}" :size="'c24'" :active="false" />
                  <Transition @enter="this.event_enter" @leave="this.event_leave">
                    <span v-if="this.btns_text['l_4']"
                      class="mlp-8" :style="'white-space: nowrap;'">{{ this.$t('views.Home.29') }}
                    </span>
                  </Transition> 
                </Button> 
              </div>
            </div>
          </div>
          <div class="d-flex col-12 flex-column flex-md-row justify-content-center align-items-end">
            <span v-if="this.selected_curr" class="HelveticaNeue light lh07 prp-20" 
            :class="{'mbp-20 text-end text-center col-12': this.$store.state.resize.w <= 750, 'text-end': this.$store.state.resize.w > 750}"
            v-html="this.sumMask(String(this.$lib.precRound(this, this.selected_curr['balance'], this.selected_curr['precision'])))">
            </span>
            <div class="d-flex flex-row justify-content-between align-items-center" :class="{'col-12': this.$store.state.resize.w <= 750}">
              <SelectSimple 
                id='selected_curr'
                :class="{'col-12': this.$store.state.resize.w <= 750, 'w22': this.$store.state.resize.w > 750}"
                :dataValue='this.selected_curr_key'
                :keywords.sync='this.currs'
                @updateParentState="this.updateState"
                :hotUpdate="true"
                :isSearch="true"
                        />
            </div>
            <div v-if="this.selected_curr && this.$store.state.resize.w <= 750" class="d-flex col-12 flex-row justify-content-between align-items-center">
              <div class="d-flex flex-column justify-content-between align-items-start col-6 prp-10">
                <Button :name="''" class="mtp-20"  :style="'width: 100%'" v-on:click='this.$router.push("/pay")' type='btn-warning'>
                  <div class="col-8 d-flex flex-row align-items-center justify-content-center">
                    <IconC :name="'withdraw'" :color="{0: '#FFFFFF', 1: '#FFA234', 2: '#FFA234'}" :size="'c24'" :active="false" />
                    <span class="mlp-10">{{ this.$t('views.Home.26') }}</span>
                  </div>
                </Button> 
                <Button :name="''" class="mtp-20" :style="'width: 100%'" v-on:click='() => {this.PayIn(this.selected_curr.keyt)}' type='btn-warning'>
                  <div class="col-8 d-flex flex-row align-items-center justify-content-center">
                    <IconC :name="'deposit'"  :color="{0: '#FFFFFF', 1: '#FFA234', 2: '#FFA234'}" :size="'c24'" :active="false" />
                    <span class="mlp-10">{{ this.$t('views.Home.27') }}</span>
                  </div>
                </Button> 
              </div>
              <div class="d-flex flex-column justify-content-between align-items-end col-6 plp-10">
                <Button :name="''" class="mtp-20" :style="'width: 100%'" v-on:click='this.$router.push("/p2p/offers")' type='btn-warning'>
                  <div class="col-8 d-flex flex-row align-items-center justify-content-center">
                    <IconC :name="'horizontal_arr'" :color="{0: '#FFA234', 1: '#FFFFFF'}" :size="'c24'" :active="false" />
                    <span class="mlp-10">{{ this.$t('views.Home.28') }}</span>
                  </div>
                </Button> 
                <Button :name="''" class="mtp-20" :style="'width: 100%'" v-on:click='this.$router.push("/transfer")' type='btn-warning'>
                  <div class="col-8 d-flex flex-row align-items-center justify-content-center">
                    <IconC :name="'user'" :color="{0: '#FFA234', 1: '#FFFFFF'}" :size="'c24'" :active="false" />
                    <span class="mlp-10">{{ this.$t('views.Home.29') }}</span>
                  </div>
                </Button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.keyts">
      <h4 class='neue-roman mb1r' v-if='this.k_name'>{{ $t('views.Home.7') }}</h4>
      <h4 class='neue-roman mb1r' v-else>{{ $t('views.Home.8') }}</h4>
      <KeytsTable :data="this.keyts" class="mb-3" :of_stage="this.of_stage" @updateStage="this.changeStage"/>
    </div>
    <div class='individual_dep1' v-if="this.ready">
      <div :class="{'sub_dep_1': this.news.length > 0, 'w-100': this.news.length == 0}">
        <div class="col-md-12" v-if="this.$store.state.userData.last_au && this.$store.state.userData.last_au.paypassword_need != 0">
          <div class='d-flex p-0 g-3 align-items-end mb1r'>
            <h4 class='mb-0 neue-roman'>{{ $t('views.Home.13') }}</h4>
          </div>
          <div class='content p-4 d-flex flex-column align-items-start justify-content-start' >
            <span class="neue color-warning">{{ $t('views.Home.14') }}</span>
            <Button :name="this.$t('views.Home.24')" class="mt-2" v-on:click='this.$router.push("/safety/pay_pass")' type='btn-warning'/> 
          </div>
        </div>
      </div>
      <div v-if="this.news.length > 0" class='sub_dep_2'>
        <div class='mb1r'>
          <h4 class='mb-0 neue-roman'>{{ $t('views.Home.19') }}</h4>
        </div>
        <div class='d-flex p-0 g-3 flex-column align-items-center justify-content-between'>
          <div v-for="(row, index) in this.news" :key="index" class='content p-4 col-12'>
            <div class='d-flex p-0 g-3 flex-row align-items-center justify-content-between'>
              <h5 class='neue' :class="{'color-newsg': row.priority == '1', 'color-warning': row.priority == '2'}">{{row.title}}</h5>
              <h5 class='light fs_08'>{{row.date_create}}</h5>
            </div>
            <p v-html="'<p>'+row.msg+'</p>'"></p>
            <p v-if='row.show_m' v-html="'<p>'+row.msg_more+'</p>'"></p>
            <p v-if='row.msg_more' v-on:click='this.show_m(index)' class='color-acent pointer mb-0'>
              <span v-if='row.show_m'>{{ $t('views.Home.20') }}</span>
              <span v-else>{{ $t('views.Home.21') }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <CustomLoader 
                 :fullPage="false"
                 :height='100'
                 :width='100'
                 :loader='"spinner"'
                 :opacity='0'
                 />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Title from '@/components/service/Title';
import Button from '../components/service/Button.vue';
import ClipboardJS from 'clipboard';
import Icon from '@/components/icons/Icon.vue';
import CustomLoader from '@/components/service/CustomLoader';
import KeytsTable from '@/components/service/keyts/KeytsTable.vue';
import SelectSimple from '@/components/service/SelectSimple';
import IconC from "@/components/icons/Icon.vue";


export default {
  components: {
    Title,
    Button,
    Icon,
    CustomLoader,
    KeytsTable,
    SelectSimple,
    IconC
  },
  data() {
    return {
      str: 'test',
      str2: 'arrow',
      str3: 'key',
      crypto: false,
      templates: [],
      of_stage: 0,
      stage_title: {
        0: this.$t('views.Home.30'),
        1: this.$t('views.Home.32'),
        2: this.$t('views.Home.34')
      },
      pays: [],
      news: [],
      ready: false,
      keyts: false,
      currs: false,
      selected_curr: false,
      selected_curr_key: false,
      keyts_length: false,
      templates_length: false,
      storage_key: 'preferred curr',
      btns_text: {},
      k_name: this.$store.state.config["KEYT_ALTERNAME_TOKEN"],
    }
  },
  created() {
    this.$store.commit('TITLE', 'HomePage');

    // console.log('store this.$store.state home page', this.$store.state);
    // console.log('this.$store.atmIncassRest home page', this.$store.state.atmIncassRest);
  },
  mounted() {
    // this.keyts = this.getKeyts();
    // console.log(this.keyts)
    this.crypto = process.env.VUE_APP_INDIVIDUAL == 'Crypto' ? true : false
    // this.getTemplates();
    this.axios.post('Init', {no_loader: true}).then(res => {
      // this.pays = res.data.body[0] || [];
      this.news = res.data.body[1] || [];
      this.ready = true;
      new ClipboardJS('.clip_h');
      new ClipboardJS('.cli_2');
    });
  },
  computed: {
        t () {
            let k = this.$store.getters.get_sort_keyts;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
  },
  watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
  },
  methods: {
    loki() {
      this.axios.post('dev_loki');
    },
    event_enter(el) {
      gsap.fromTo(el, { opacity: 0, width: 0 }, { opacity: 1, width: 'auto', duration: 0.4 });
    },
    event_leave(el, done) {
      gsap.fromTo(el, { opacity: 1 }, { opacity: 0.1, width: 0, duration: 0.4, onComplete: done });
    },
    payTemplate(arg) {
      if (arg.type == 'pay') {
        this.$router.push({name: 'Pay', query: {
                  template: arg.num
                }})
      }
      if (arg.type == 'tran') {
        this.$router.push({name: 'Transfer_id', query: {
                  template: arg.num,
                }})
      }
      if (arg.type == 'payin') {
        this.$router.push({name: 'Payin', query: {
                  template: arg.num,
                }})
      }
    },
    changeStage(arg) {
      this.of_stage = arg;
    },
    PayIn(arg) {
      let params = {
        keyt: arg,
      };
      this.$router.push({name: 'Payin', query: params})
    },
    payOperator(arg) {
      this.$router.push({name: 'Pay', query: {
                   operator: arg.code,
                   name: arg.name
                }})
    },
    getKeyts(k) {
        let arr = structuredClone(k);
        let s = structuredClone(this.$store.getters.get_currs_keyts_sum);
        // console.log('keyt_sum', s);
        // if (arr.length > 2) {
        //   this.keyts_length = arr.length;
        //   arr.splice(2, arr.length);
        // }
        let tmp = [];
        let tmp_currs = [];
        if (this.of_stage === 0) {
            tmp = arr;
            tmp_currs = s;
        } else {
            arr.forEach(e => {
                if (this.of_stage === 1 && e.fiat === '0') {
                    tmp.push(e);
                } else if (this.of_stage === 2 && e.fiat === '1') {
                    tmp.push(e)
                }
            });
            s.forEach(e => {
                if (this.of_stage === 1 && e.fiat === '0') {
                    tmp_currs.push(e);
                } else if (this.of_stage === 2 && e.fiat === '1') {
                    tmp_currs.push(e)
                }
            });
        }
        this.keyts_length = tmp.length;
        this.keyts = tmp;
        
        if (tmp_currs.length > 0) {
          let pref_curr = localStorage.getItem(this.storage_key);
          if (pref_curr !== null) {
            tmp_currs.forEach(e => {
              if (e.code == pref_curr) {
                this.selected_curr = e;
                this.selected_curr_key = e.code;
              }
            })
          }
          if ( !this.selected_curr ) {
            this.selected_curr = tmp_currs[0];
            this.selected_curr_key = tmp_currs[0]['code'];
            localStorage.setItem(this.storage_key, this.selected_curr_key);
          }
        }
        this.currs = tmp_currs;
    },
    getTemplates() {
      if (this.$store.state.userData.templates) {
        let cat = this.$store.state.userData.templates.catalog.slice(0);
        cat.forEach(e => {
          e.type = 'pay';
        });
        let tran = this.$store.state.userData.templates.transfer.slice(0);
        tran.forEach(e => {
          e.type = 'tran';
        });
        let payin = this.$store.state.userData.templates.payin.slice(0);
        payin.forEach(e => {
          e.type = 'payin';
        });
        this.templates_length = cat.length + tran.length + payin.length;
        if (cat.length > 2) {
          cat.splice(2, cat.length)
        }
        if (tran.length > 2) {
          tran.splice(2, tran.length)
        }
        if (payin.length > 2) {
          payin.splice(2, payin.length)
        }
        this.templates = cat.concat(tran, payin);
        // console.log(this.templates);
      } else {
        setTimeout(() => {
          this.getTemplates();
        }, 2000);
      }
    },
    updateState(arg) {
      // console.log(arg);
      // console.log(this.currs)
      this.selected_curr = false;
      this.selected_curr_key = arg.value;
      localStorage.setItem(this.storage_key, this.selected_curr_key);
      this.currs.forEach(e => {
        if (arg.value == e['code']) {
          this.selected_curr = e;
        }
      })
    },
    PayIn(arg) {
      let params = {
        keyt: arg,
      };
      this.$router.push({name: 'Payin', query: params})
    },
    sbstring(str) {
      if (str.length > 21) {
        return str.substring(0, 21) + '...';
      } else {
        return str;
      }
    },
    sumMask(sum) {
      let arr = sum.split('.');
      if (arr[0].length > 9) {
        let humVal = this.$lib.bignumberToHumanValue(arr[0]);
        return `<span  class='fsp50'>${humVal[0]} ${humVal[1]}</span>`
      }
      if (arr[1]) {
        return `<span class="fsp50">${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}.</span><span class='fsp36'>${arr[1]}</span>`
      } else {
        return `<span class="fsp50">${arr[0].replace(/(\d)(?=(\d\d\d)+\b)/ig, '$1  ')}</span>`
      }
    },
    show_m(index) {
      this.news[index].show_m = !this.news[index].show_m;
    },
  },
}
</script>
<style lang="scss">
.w30 {
    margin-right: 5%;
    @media (min-width: 661px) {
        width: 30% !important;
    }
    @media (max-width: 660px) {
        margin-right: 10%;
        width: 45% !important;
    }
    @media (max-width: 480px) {
        width: 100% !important;
    }
}
.w22 {
  width: 220px !important;
}
.cell-btn {
  font-size: 16px; 
  margin-left: 20px;
  overflow: hidden;
}
.individual_dep1 {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.pf-05 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.mb1r {
  margin-bottom: 1rem;
}
.h20 {
  height: 27px !important;
}
.k_table {
  .dep_3 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  tr{ 
    td {
      padding: 0.9rem 0.5rem;
    }
    td:nth-child(1) {
      padding-left: 0 !important;
      div {
        padding: 0.2rem;
        border-radius: 50%;
        color: white;
        font-size: 1.3rem;
        height: 39px;
        width: 39px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
          height: 30px;
        }
      }
    }
    td:nth-child(2) {
      line-height: 1.1rem;
      padding-top: 1rem !important;
      padding-bottom: 0.8rem !important;
    }
    td:nth-child(3) {
      text-align: right;
      white-space: nowrap;
    }
    td:last-child {
      padding-right: 0 !important;
    }
  }
  tr:last-child {
    border: none;
    td {
      border: none;
    }
  }
}
.height_fix {
  min-height: 165.78px;
}
  .individual_dep1 {
    @media (min-width: 992px) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .sub_dep_1 {
      @media (min-width: 992px) {
        width: 27%;
        .w30 {
          margin-right: 0 !important;
          width: 100% !important;
        }
      }
    }
    .sub_dep_2 {
      @media (min-width: 992px) {
        width: 70%;
      }
    }
  }  

@media (min-width: 661px) {
    .w30:nth-child(3n) {
        margin-right: 0 !important;
    }
}
@media (max-width: 660px) {
    .w30:nth-child(2n) {
        margin-right: 0 !important;
    }
}
@media (max-width: 480px) {
    .w30 {
        margin-right: 0 !important;
    }
}
.wh-37 {
  @media (min-width: 992px) {
      width: 32%;
  }
}
.wh-60 {
  @media (min-width: 992px) {
      width: 65%;
  }
}
</style>